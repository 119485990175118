/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.css.map */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -80px;
      top: 80px;
      transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -80px;
      top: 80px;
      transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

@charset "UTF-8";

/* RESET */
@import "reset";

/* INVENTION FONTS */
@font-face {
  font-family: "Invention Black Italic";
  src: url("/fonts/invention/EOT/Invention_W_XBdIt.eot");
  src: url("/fonts/invention/EOT/Invention_W_XBdIt.eot") format("eot"), url("/fonts/invention/WOFF2/Invention_W_XBdIt.woff2") format("woff2"),
    url("/fonts/invention/WOFF/Invention_W_XBdIt.woff") format("woff");
}
@font-face {
  font-family: "Invention Black";
  src: url("/fonts/invention/EOT/Invention_W_XBd.eot");
  src: url("/fonts/invention/EOT/Invention_W_XBd.eot") format("eot"), url("/fonts/invention/WOFF2/Invention_W_XBd.woff2") format("woff2"),
    url("/fonts/invention/WOFF/Invention_W_XBd.woff") format("woff");
}
@font-face {
  font-family: "Invention Bold";
  src: url("/fonts/invention/EOT/Invention_W_Bd.eot");
  src: url("/fonts/invention/EOT/Invention_W_Bd.eot") format("eot"), url("/fonts/invention/WOFF2/Invention_W_Bd.woff2") format("woff2"),
    url("/fonts/invention/WOFF/Invention_W_Bd.woff") format("woff");
}
@font-face {
  font-family: "Invention Bold Italic";
  src: url("/fonts/invention/EOT/Invention_W_BdIt.eot");
  src: url("/fonts/invention/EOT/Invention_W_BdIt.eot") format("eot"), url("/fonts/invention/WOFF2/Invention_W_BdIt.woff2") format("woff2"),
    url("/fonts/invention/WOFF/Invention_W_BdIt.woff") format("woff");
}
@font-face {
  font-family: "Invention Italic";
  src: url("/fonts/invention/EOT/Invention_W_It.eot");
  src: url("/fonts/invention/EOT/Invention_W_It.eot") format("eot"), url("/fonts/invention/WOFF2/Invention_W_It.woff2") format("woff2"),
    url("/fonts/invention/WOFF/Invention_W_It.woff") format("woff");
}
@font-face {
  font-family: "Invention Regular";
  src: url("/fonts/invention/EOT/Invention_W_Rg.eot");
  src: url("/fonts/invention/EOT/Invention_W_Rg.eot") format("eot"), url("/fonts/invention/WOFF2/Invention_W_Rg.woff2") format("woff2"),
    url("/fonts/invention/WOFF/Invention_W_Rg.woff") format("woff");
}
@font-face {
  font-family: "Invention Light Italic";
  src: url("/fonts/invention/EOT/Invention_W_LtIt.eot");
  src: url("/fonts/invention/EOT/Invention_W_LtIt.eot") format("eot"), url("/fonts/invention/WOFF2/Invention_W_LtIt.woff2") format("woff2"),
    url("/fonts/invention/WOFF/Invention_W_LtIt.woff") format("woff");
}
@font-face {
  font-family: "Invention Light";
  src: url("/fonts/invention/EOT/Invention_W_Lt.eot");
  src: url("/fonts/invention/EOT/Invention_W_Lt.eot") format("eot"), url("/fonts/invention/WOFF2/Invention_W_Lt.woff2") format("woff2"),
    url("/fonts/invention/WOFF/Invention_W_Lt.woff") format("woff");
}

// Default Variables
@import "variables";
@import "mixins";
@import "colors";
@import "tenyearlanding.scss";

* {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

#hamburger {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 99999;
  border: none;

  &:focus {
    outline: 0;
  }
}

.hamburger-inner {
  background: $darkBlueColor !important;
  height: 5px !important;

  &::before,
  &::after {
    height: 5px !important;
    background: $darkBlueColor !important;
  }
}

* {
  &:focus {
    outline: none !important;
  }
}

// Main Site Styles
body {
  font-family: $bodyCopy;
  background: $whiteColor;
  color: $textColor;
  font-weight: normal;
  @include selection-color($whiteColor, $tealColor);

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $headers;
    color: $darkBlueColor;

    @media screen and (max-width: $break-small) {
      text-align: center;
    }
  }

  h1 {
    font-weight: normal;
    @include font-size(28);
    @include line-height(30);
    @include margin(5px, 0px, 5px, 0px);
    @include padding(5px, 0px, 5px, 0px);

    @media screen and (max-width: $break-large) {
      @include font-size(28);
      @include line-height(30);
    }
    @media screen and (max-width: $break-medium) {
      @include font-size(25);
      @include line-height(26);
    }
    @media screen and (max-width: $break-small) {
      @include font-size(23);
      @include line-height(24);
    }
  }

  h2 {
    font-weight: bold;
    @include font-size(19);
    @include line-height(21);
    @include margin(0px, auto, 0px, auto);
    @include padding(12px, 0px, 12px, 0px);

    @media screen and (max-width: $break-large) {
      @include font-size(17);
      @include line-height(22);
    }
  }

  h3,
  h4,
  h5 {
    font-weight: bold;
    @include margin(0px, 0px, 0px, 0px);
    @include padding(0px, 0px, 0px, 0px);
  }

  h3 {
    @include font-size(14);
    @include line-height(19);
    @include margin(15px, auto, 0, auto);
    @include padding(5px, 0, 5px, 0);

    @media screen and (max-width: $break-medium) {
      margin-top: 20px;
    }

    @media screen and (max-width: $break-small) {
      margin-top: 15px;
    }

    @media screen and (max-width: $break-xsmall) {
      margin-top: 10px;
    }
  }

  h4 {
    @include font-size(13);
    @include line-height(18);
  }

  h5 {
    @include font-size(12);
    @include line-height(17);
  }

  ol,
  ul {
    @include font-size(9.5);
    @include line-height(15);
    display: block;
    list-style: outside disc;
    @include margin(20px, 0, 10px, 20px);

    li {
      @include padding(0, 0, 20px, 0);
    }
  }

  p {
    width: 100%;
    font-weight: normal;
    @include font-size(10);
    @include line-height(16);
    @include margin(0, 0, 1rem, 0);
    @include padding(0, 0, 0, 0);

    &.promo {
      @include font-size(13);
      @include line-height(19.5);
      font-weight: bold;
      color: $tealColor;
      margin-left: 5%;
      @include padding(0, 150px, 0, 0);

      @media screen and (max-width: $break-small) {
        @include padding(0, 0, 0, 0);
        margin-left: 0;
      }
    }

    &.lead {
      color: $tealColor;
    }

    &.text-danger {
      color: $tealColor;
      font-style: oblique;
      text-align: center;
    }

    @media screen and (max-width: $break-small) {
      text-align: center;
    }
  }

  strong {
    font-weight: bold;
  }

  .subtitle {
    @include font-size(16);
    font-weight: bold;
    color: $tealColor;
    margin-left: 35px;

    @media screen and (max-width: $break-small) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .notification {
    @include font-size(8);
    @include line-height(8);
    @include margin(0, auto, 0, auto);
    @include padding(8px, 0, 8px, 0);
  }

  .indented {
    @include padding(8px, 8%, 8px, 5%);
  }

  .error {
    width: 100%;
    font-weight: bold;
    font-style: oblique;
    color: $redColor;
  }

  .bordered {
    border-top: 1px solid $darkBlueColor;
    border-bottom: 1px solid $darkBlueColor;
  }

  .icons {
    i {
      margin-right: 50px;

      @media screen and (max-width: $break-small) {
        @include margin(0, auto, 0, auto);
        @include padding(0, 15px, 0, 15px);
      }
    }

    @media screen and (max-width: $break-small) {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  a {
    color: $linkColor;
    text-decoration: underline;
    font-weight: bold;
    @include transition(0s, 0.25s, all, ease);
    @include margin(0, 0, 0, 0);
    @include padding(0, 0, 0, 0);

    &:hover {
      color: $tealHoverColor;
      text-decoration: none;
      border-bottom: none;
    }
  }

  img {
    width: $full-width;
    display: block;
  }

  hr {
    border: none;
    border-top: 1px solid $lightGrayColor;
  }

  .horizontalRule {
    width: 100%;
    border-top: 2px solid #f7f7f7;
    margin: 5px 0;
  }

  sup {
    font-size: 65%;
    line-height: 0.7rem;
  }

  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }
  .float-none {
    float: none;
  }

  .display-flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .center-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .justify-center {
    justify-content: center;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    float: left;
    @include margin(0, auto, 0, auto);
    flex-wrap: wrap;
  }

  section {
    width: 100%;
    @include margin(0, auto, 0, auto);
    @include padding(10px, 0, 10px, 0);
  }

  .row {
    @media screen and (max-width: $break-small) {
      @include margin(0, 0, 0, 0);
    }
  }

  nav {
    &#header {
      background: $whiteColor;
      @include margin(0, auto, 0, auto);
      @include box-shadow(unquote("0 1px 14px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset"));

      &:before {
        top: 50%;
        bottom: 0;
        left: 10px;
        right: 10px;
        @include box-shadow(0 0 5px rgba(0, 0, 0, 0.6));
      }

      &:after {
        content: "";
        position: absolute;
        z-index: -2;
        @include box-shadow(0 0 5px rgba(0, 0, 0, 0.6));
      }

      &.photoLibrary {
        background: $tealColor;
        @include margin(0, auto, 0, auto);
        @include box-shadow(unquote("0 1px 14px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset"));

        img {
          border-right: 1px solid $whiteColor;
        }

        .navbar-nav {
          li {
            color: $lightGrayColor;
          }
          .nav-link {
            @include padding(0.5rem, 0.5rem, 0.5rem, 0.5rem);
          }
        }

        .navbar-nav > li > a {
          color: $whiteColor;
          text-transform: uppercase;
          padding-top: 5px;
          padding-bottom: 5px;
          text-decoration: none;

          &:hover {
            color: $tealHoverColor;
          }
        }
      }

      #navigator {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        &.show {
          display: block;
        }

        &:not(.show) {
          display: hidden;
        }

        &.hidden {
          display: none !important;
        }
      }

      #adminUpload {
        @media screen and (max-width: $break-small) {
          position: absolute;
          width: 100%;
          margin-top: 55px;
        }
      }

      .row {
        position: relative;
        width: 100%;
        @include padding(0, 15px, 0, 15px);
        align-items: center;
      }

      .navbar-brand {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        h1 {
          @include font-size(18);
          color: $whiteColor;
          @include margin(0, auto, 0, auto);
          @include padding(0, 0, 0, 0);

          @media screen and (max-width: $break-xsmall) {
            @include font-size(14);
          }
        }

        img {
          width: 165px;
          @include margin(0, 15px, 0, 0);
          @include padding(0, 5px, 0, 0);

          @media screen and (max-width: $break-medium) {
            width: 160px;
          }

          @media screen and (max-width: $break-xsmall) {
            width: 140px;
          }
        }

        @media screen and (max-width: $break-xsmall) {
          @include margin(0, 0, 0, 0);
          @include padding(10px, 0, 10px, 0);
        }
      }

      .navbar-nav {
        position: absolute;
        right: 0;
        align-items: center;
        flex-direction: row;
        @include margin(0, 0, 0, 0);

        .nav-item {
          @include padding(0, 10px, 0, 10px);

          &.active {
            a {
              font-family: "Invention Black";
              color: $tealColor;
            }
          }

          a:hover {
            opacity: 0.65;
          }

          @media screen and (max-width: $break-small) {
            @include padding(10px, 10px, 10px, 10px);
          }
        }

        .nav-link {
          @include padding(0, 0, 0, 0);
        }

        @media screen and (max-width: $break-large) {
          display: contents;
          flex-direction: column;
          text-align: center;
        }

        @media screen and (max-width: $break-small) {
          display: contents;
          flex-direction: column;
          text-align: center;
        }
      }
    }

    &.navbar {
      border: none;

      ol li,
      ul li {
        @include padding(0, 0, 0, 0);
      }

      .dropdown-header {
        @include padding(3px, 20px, 3px, 20px);
      }

      .dropdown-menu > li > a:hover {
        background: $tealHoverColor;
      }

      .dropdown-menu > .active > a {
        background: $lightTealColor;

        &:hover {
          color: $tealHoverColor;
        }
      }

      .navbar-nav li {
        color: $darkBlueColor;
      }

      .navbar-nav > li > a {
        @include font-size(11);
        color: $darkBlueColor;
        padding-top: 5px;
        padding-bottom: 5px;
        text-decoration: none;

        &:hover {
          color: $tealHoverColor;
        }
      }
    }

    &.navbar-default {
      border-color: none;

      .navbar-toggle {
        @include margin(15px, 15px, 15px, 15px);
        border-color: $tealColor;

        .icon-bar {
          background-color: $tealColor;
        }

        &:hover {
          background-color: $tealHoverColor;
        }
      }
      .navbar-nav > .active > a,
      .navbar-nav > .active > a:hover,
      .navbar-nav > .active > a:focus {
        background: $tealColor;
        color: $whiteColor;
      }

      .navbar-nav > .open > a,
      .navbar-nav > .open > a:focus,
      .navbar-nav > .open > a:hover {
        background: $lightTealColor;
        color: $darkBlueColor;
      }

      @media screen and (min-width: $break-medium) {
        background: rgba(255, 255, 255, 0.8);
      }

      @media screen and (min-width: $break-xsmall) and (max-width: $break-small) {
        background: rgba(255, 255, 255, 0.9);
      }
    }

    &.mini {
      .navbar-brand {
        @include padding(10px, 15px, 5px, 15px);

        & > img {
          width: 120px;
        }
      }

      .navbar-nav > li > a {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .navbar-toggle {
        @include margin(5px, 15px, 5px, 15px);
      }
    }

    .navbar-form-search {
      position: relative;
      width: 100%;

      .input-group {
        width: 100%;

        .input-group-addon {
          display: flex;
          align-items: center;
          justify-content: center;
          @include margin(0, 0, 0, 0);
          @include padding(0, 0, 0, 0);
          cursor: pointer;
        }

        .form-control {
          position: relative;
          float: none;
          height: 40px;

          .search {
            width: 90%;
          }
        }

        .btn {
          border: 0;
          background: transparent;
          font-size: 18px;

          &:active,
          &:hover,
          &:focus {
            color: #000;
            outline: none !important;
            box-shadow: none !important;
          }
        }

        .search-close {
          width: 10%;
          @include font-size(20);
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .search-form-container {
        text-align: right;
        position: absolute;
        width: 205px;
        overflow: hidden;
        background: #fff;
        right: 40px;
        top: 0;
        z-index: 9;
        transition: all 0.3s ease-in-out;

        &.hdn {
          width: 0;
        }

        .search-input-group {
          width: 205px;

          .addsearch {
            background-image: none !important;
          }
        }
      }
    }
  }

  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse {
    max-height: 380px;
  }

  .search-form-wrapper {
    display: none !important;
    opacity: 0;
    position: absolute;
    width: 60%;
    right: 0;
    padding: 0 10px;
    margin-top: 0px;
    z-index: 100;
    @include transition(0s, 0.2s, all, ease-in-out);

    &.open {
      display: flex !important;
      opacity: 1;
    }
  }

  .input-group-addon {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include margin(0, 0, 0, 0);
    @include padding(0, 0, 0, 0);
    cursor: pointer;
  }

  .search-form-trigger {
    padding: 5px 0 !important;
    border: 0 none !important;
    @include font-size(15);
    color: $darkBlueColor;
    cursor: pointer;
  }

  .top-social .fab {
    color: #494949;
    font-size: 1.8em;
    padding-right: 8px;
  }

  .map-bg {
    // background: url('../images/map-gray-bg.png') no-repeat center top;
    background-size: contain;
    background-color: #e7e8ea;
    padding: 60px 0;
    // height: 90vh;
    position: relative;

    @media screen and (max-width: $break-small) {
      background: url("../images/map-gray-bg.png") no-repeat center left;
      background-color: #e7e8ea;
      background-size: 168%;
      padding: 25px 0;
      // background-position-x: -290px;
    }
  }

  .map-bg:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 50px #e7e8ea;
    border-left: solid 50px transparent;
    border-right: solid 50px transparent;
  }

  .featured-panelist {
    background-color: #09192c;
    padding: 60px 0;

    .title {
      background-color: $tealColor;
      width: 100%;
    }

    .table {
      background-color: #fff;
      padding: 50px;

      p {
        font-size: 18px;
        @media screen and (max-width: $break-small) {
          text-align: left;
        }
      }
    }
  }

  .arrow-down {
    position: absolute;
    width: 0;
    height: 0;
    left: 47%;
    bottom: -50px;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.25s;
    -webkit-transition-timing-function: ease-in-out;
    -webkit-transition-delay: 0.12s;
    transition-property: all;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
    transition-delay: 0.12s;
    z-index: 1;
  }

  #banner {
    @include padding(0, 0, 0, 0);
    height: 75vh;
    min-height: 275px;
    display: flex;
    align-items: center;

    h1 {
      display: block;
      @include font-size(26);
      @include line-height(40);
      font-weight: bold;
      color: $whiteColor;
      text-shadow: 1px 2px 8px rgba(0, 0, 0, 0.7);
      @include margin(0, auto, 0, auto);
      @include padding(20px, 20px, 20px, 20px);
      @include border-radius(10px, 10px, 10px, 10px);

      @media screen and (max-width: $break-large) {
        @include font-size(20);
        @include line-height(35);
      }

      @media screen and (min-width: $break-xsmall) and (max-width: $break-small) {
        @include font-size(15);
        @include line-height(24);
        text-align: center !important;
        text-shadow: 1px 2px 10px rgba(0, 0, 0, 0.9);
      }
    }

    &.homepage {
      // background: url('../images/banner-home.jpg') fixed no-repeat center top;
      background: url("../images/banner_home_new.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/banner-home.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -290px;
      }
    }

    &.about {
      background: url("../images/banner-whoWeAre.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/banner-whoWeAre.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -290px;
      }
    }

    &.whatWeDo {
      background: url("../images/banner-whatWeDo.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/banner-whatWeDo.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -270px;
      }

      &.digitalInnovations {
        background: url("../images/banner-digitalInnovations.jpg") fixed no-repeat center top;
        background-size: cover;
        height: 90vh;

        @media screen and (max-width: $break-small) {
          background: url("../images/banner-digitalInnovations.jpg") no-repeat center left;
          background-size: 375%;
          background-position-x: -485px;
        }
      }
    }

    &.whereWeWork {
      background: url("../images/banner-whereWeWork.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/banner-whereWeWork.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -285px;
      }
    }

    &.learnMore {
      background: url("../images/banner-learnMore.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/banner-learnMore.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -360px;
      }
    }

    &.getInvolved {
      background: url("../images/banner-getInvolved.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/banner-getInvolved.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -375px;
      }
    }

    &.country {
      h1 {
        @include font-size(50);
        @include line-height(50);

        @media screen and (max-width: $break-xsmall) {
          @include font-size(30);
          @include line-height(30);
        }
      }
    }

    &.india {
      background: url("../images/country-banner-india.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/country-banner-india.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -650px;
      }
    }

    &.nigeria {
      background: url("../images/country-banner-nigeria.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/country-banner-nigeria.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -480px;
      }
    }

    &.usa {
      background: url("../images/country-banner-usa.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/country-banner-usa.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -140px;
      }
    }

    &.kenya {
      background: url("../images/country-banner-kenya.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/country-banner-kenya.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -300px;
      }
    }

    &.grants {
      background: url("../images/country-banner-global-grants.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      @media screen and (max-width: $break-small) {
        background: url("../images/country-banner-global-grants.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -700px;
      }
    }

    &.anniversary {
      // background: url('../images/banner-10th-anniversary.jpg') fixed no-repeat
      //   center top;
      // background-size: cover;
      background-color: #00857c;
      height: 55vh;
      position: relative;

      @media screen and (max-width: $break-large) {
        height: 50vh !important;
      }

      // @media screen and (max-width: $break-medium) {
      //   height: 80vh !important;
      // }

      @media screen and (max-width: $break-small) {
        // background: url('../images/banner-10th-anniversary.jpg') no-repeat
        //   center left;
        // background-size: cover;
        // height: 50vh !important;
        height: 40vh !important;
      }

      .text-block {
        margin-top: 7%;
        @media screen and (max-width: $break-small) {
          margin-top: 0%;
        }
      }

      h1 {
        font-size: 3rem;
        padding-top: 20px;

        @media screen and (max-width: $break-large) {
          font-size: 2.2rem;
        }

        @media screen and (max-width: $break-medium) {
          font-size: 1.8rem;
          line-height: 1.6em;
        }

        @media screen and (max-width: $break-small) {
          font-size: 1.4rem;
          line-height: 1.6em;
          text-align: right !important;
        }
      }

      img {
        width: 260px;
        position: absolute;
        // top: 150px;
        top: -60px;
        right: -10px;
        @media screen and (max-width: $break-large) {
          width: 220px;
        }

        @media screen and (max-width: $break-small) {
          width: 150px;
          top: -65px;
        }

        // @media screen and (max-width: $break-xsmall) {
        //     width:150px;
        //     top: 60px;
        // }
      }

      .arrow-down {
        // background: url('../images/banner-10th-anniversary.jpg') fixed no-repeat;
        // background-size: cover;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        // border-top: 50px solid transparent;
        border-top: 50px solid #00857c;

        @media screen and (max-width: $break-large) {
          left: 45%;
          // background-position-x: -136px;
        }

        @media screen and (max-width: $break-medium) {
          left: 42%;
          // background-position-x: -172px;
        }
      }
    }

    &.allformothers {
      background: url("../images/all-for-mothers-bg-hero.jpg") fixed no-repeat center top;
      background-size: cover;
      height: 90vh;

      h1 {
        @include font-size(35);
        @include line-height(35);
        text-align: center;
        border-top: 2px solid $whiteColor;
        border-bottom: 2px solid $whiteColor;
        margin-bottom: 25px;
      }

      p {
        @include font-size(12);
        @include line-height(16);
        font-weight: bold;
        color: $whiteColor;
        text-align: center;
        text-shadow: 1px 2px 8px rgba(0, 0, 0, 0.7);
      }

      @media screen and (max-width: $break-small) {
        background: url("../images/all-for-mothers-bg-hero.jpg") no-repeat center left;
        background-size: 375%;
        background-position-x: -850px;
      }
    }

    @media screen and (max-width: $break-large) {
      height: 75vh !important;
    }

    @media screen and (max-width: $break-medium) {
      height: 65vh !important;
    }

    @media screen and (max-width: $break-xsmall) {
      height: 55vh !important;
    }
  }

  .arrow-block {
    position: absolute;
    bottom: 0;
    width: 100%;
    // height: 20px;
    background-color: #e7e8ea;
  }
  .arrow-block:before,
  .arrow-block:after {
    content: "";
    position: absolute;
    bottom: 100%;
    width: 50%;
    box-sizing: border-box;
  }
  .arrow-block:before {
    right: 50%;
    border-bottom: 50px solid #e7e8ea;
    border-right: 50px solid transparent;
  }
  .arrow-block:after {
    left: 50%;
    border-bottom: 50px solid #e7e8ea;
    border-left: 50px solid transparent;
  }

  #content {
    @include padding(30px, 0, 30px, 0);

    @media screen and (min-width: $break-xsmall) {
      @include padding(20px, 0, 20px, 0);
    }

    @media screen and (min-width: $break-medium) {
      @include padding(25px, 0, 25px, 0);
    }

    @media screen and (min-width: $break-large) {
      @include padding(30px, 0, 30px, 0);
    }
  }

  .btn-primary,
  .btn-secondary,
  .btn-red,
  .btn-inverse {
    display: inline-block;
    background: $tealColor;
    color: $whiteColor;
    text-decoration: none;
    border: 1px solid $tealColor;
    @include border-radius(8px, 8px, 8px, 8px);
    @include padding(10px, 10px, 10px, 10px);
    @include box-shadow(inset 0 0 0 0 $tealHoverColor);
    @include transition(0.2s, 0.6s, all, ease-in-out);
    cursor: pointer;

    a {
      color: $whiteColor;

      &:hover {
        color: $tealHoverColor;
      }
    }

    &.smallBtn {
      float: left;
      width: 31%;
      margin-right: 5px !important;
    }

    &:hover {
      background: $tealColor;
      color: $whiteColor;
      border: 1px solid $tealColor;
      @include padding(10px, 10px, 10px, 10px);
      @include box-shadow(inset 400px 0 0 0 $tealHoverColor !important);
    }

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background: $tealColor;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .btn-secondary {
    background: $whiteColor;
    color: $tealColor;

    a {
      color: $tealColor;

      &:hover {
        color: $lightGrayColor;
      }
    }

    &:hover {
      background: $whiteColor;
      color: $lightTealColor;
      @include padding(10px, 10px, 10px, 10px);
      @include box-shadow(inset 400px 0 0 0 $tealHoverColor !important);
    }

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background: $whiteColor;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .btn-inverse {
    background: $tealColor;
    border: 1px solid $whiteColor;
    color: $whiteColor;

    a {
      color: $tealColor;

      &:hover {
        color: $lightGrayColor;
      }
    }

    &:hover {
      background: $tealColor;
      color: $lightGrayColor;
      @include padding(10px, 10px, 10px, 10px);
      @include box-shadow(inset 400px 0 0 0 $tealHoverColor !important);
    }
  }

  .btn-outline-primary {
    color: $whiteColor;
    border-color: $tealColor;
    text-decoration: none;

    &:hover {
      color: $darkBlueColor;
      border-color: $whiteColor;
      background-color: $whiteColor;
    }
  }

  .btn-red {
    background-color: $redColor;
    border-color: $redColor;
    color: $whiteColor;

    a {
      color: $whiteColor;

      &:hover {
        color: $lightGrayColor;
      }
    }

    &:hover {
      background-color: $redColor;
      border-color: $darkBlueColor;
      @include box-shadow(inset 400px 0 0 0 $darkBlueColor !important);
    }

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background: $redColor;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  #countBar {
    @include padding(25px, 0, 25px, 0);

    .counter {
      @include font-size(45);
      @include line-height(45);
      font-weight: bold;
      color: $whiteColor;
      @include margin(10px, auto, 10px, auto);
      @include transition(0, 0.2s, all, ease-in-out);
    }

    .description {
      @include font-size(13);
      @include line-height(16);
      font-weight: bold;
      color: $whiteColor;
    }
  }

  #article {
    background: $whiteGrayColor;
    @include padding(30px, 0, 30px, 0);

    &.main {
      background: $whiteGrayColor;
    }

    &.features {
      div {
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &.compendium {
      background: url("/images/compendium-bkgd.jpg") no-repeat center center;
      background-size: cover;
      @include padding(100px, 0, 100px, 0);

      div {
        justify-content: normal;
      }

      @media screen and (max-width: 768px) {
        background: url("/images/compendium-mobile-bkgd.jpg") no-repeat center center;
        background-size: cover;
        @include padding(30px, 0, 30px, 0);
      }

      @media screen and (max-width: 732px) {
        background: $offWhiteColor;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  #callouts {
    background: $whiteGrayColor;
    @include padding(30px, 0, 30px, 0);

    .callout {
      @include margin(25px, 15px, 25px, 15px);
    }

    .reach {
      @extend .callout;
      border-top: 2px solid $tealColor;
    }

    .impact {
      @extend .callout;
      border-top: 2px solid $tealHoverColor;
    }

    .initiatives {
      @extend .callout;
      border-top: 2px solid $darkBlueColor;
    }
  }

  .frazier-award {
    h1,
    p,
    a {
      color: $whiteColor;
    }
  }

  #leadership {
    @include padding(30px, 0, 30px, 0);

    .leader {
      @include margin(0, auto, 20px, auto);
      @include padding(0, 0, 0, 0);
      cursor: pointer;

      p {
        @include font-size(8);
        @include line-height(9);
        color: $grayColor;
        @include padding(5px, 0, 5px, 0);
      }

      .col-6 {
        @include padding(0, 15px, 0, 0);
      }

      &:hover {
        h4,
        p {
          color: $tealColor;
        }
      }
    }
  }

  #advisory,
  #publications {
    background: $whiteGrayColor;
    @include padding(30px, 0, 30px, 0);

    .contents {
      display: block;
      border-left: 1px solid $tealColor;
      @include margin(0, auto, 50px, auto);
      @include padding(10px, 0, 25px, 0);

      h4,
      h5 {
        @include margin(0, 0, 0, 0);
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 10px;

        a {
          text-decoration: none;
        }
      }

      p {
        @include font-size(9);
        @include line-height(11);
        color: $grayColor;
        @include margin(0, 0, 0, 0);
        @include padding(5px, 10px, 10px, 25px);
      }

      @media screen and (max-width: $break-small) {
        height: auto !important;
        border-top: 1px solid $tealColor;
        border-left: none;
      }
    }

    .member {
      height: 210px;
      @extend .contents;
    }

    .pub {
      height: 250px;
      @extend .contents;

      @media screen and (max-width: $break-large) {
        height: 350px;
      }
    }
  }

  .investmentBrief {
    @extend .display-flex;
    @include margin(0, auto, 50px, auto);
    @include padding(0, 5%, 0, 5%);

    h5 {
      color: $tealColor;
    }

    .collaborators {
      @include margin(15px, auto, 0, auto);
      @include padding(0, 0, 0, 20px);
      border-left: 1px solid $tealColor;

      ul {
        li {
          @include margin(0, auto, 0, auto);
          @include padding(0, 0, 5px, 0);
          font-weight: bold;
        }

        @media screen and (max-width: $break-small) {
          @include margin(0, auto, 0, auto);
          list-style: none;
          text-align: center;
        }
      }

      @media screen and (max-width: $break-small) {
        @include padding(0, 0, 0, 0);
        border-left: none;
      }
    }

    @media screen and (max-width: $break-small) {
      @include padding(0, 0, 0, 0);
    }
  }

  #factSheets {
    @include padding(30px, 0, 30px, 0);

    .filter-resource {
      @include margin(0, auto, 0, auto);
      @include padding(0, 10px, 0, 0);

      input[type="radio"] {
        display: none;

        &:checked + label {
          font-family: "Invention Bold";
          color: $darkBlueColor;
        }
      }

      label {
        width: 100%;
        color: $tealColor;
        cursor: pointer;
      }
    }

    .resource {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      min-height: 500px;
      @include margin(0, auto, 0, auto);
      @include padding(0, 0, 0, 0);
      overflow-y: hidden;

      &.featured {
        min-height: auto;
      }

      .element-item {
        width: 33%;
        flex: 1 0 33%;
        @include margin(0, 0, 0, 0);
        @include padding(20px, 20px, 20px, 20px);

        a {
          color: $darkBlueColor;
          text-decoration: none;

          &:hover > h4 {
            color: $tealColor;
          }
        }

        h4 {
          color: $darkBlueColor;
          @include padding(5px, 0, 5px, 0);
          @include transition(0, 0.2s, all, ease-in-out);
        }

        @media screen and (max-width: $break-small) {
          width: 100%;
          flex: 1 0 100%;
        }
      }

      .hidden {
        display: none;
      }

      @media screen and (max-width: $break-small) {
        min-height: auto;
      }
    }
  }

  #initiatives {
    @include padding(0px, 0, 30px, 0);

    div {
      @include margin(30px, auto, -30px, auto);
      @include padding(0, 0, 0, 0);

      @media screen and (max-width: $break-xsmall) {
        @include margin(0, auto, 0, auto);
      }
    }

    .textbox {
      @include margin(10px, auto, 10px, auto);
      @include padding(30px, 30px, 30px, 30px);

      h4,
      p {
        @include margin(10px, auto, 10px, auto);
        @include padding(10px, 0, 10px, 0);
      }
    }
  }

  #map {
    @include padding(30px, 0, 30px, 0);

    strong {
      width: 100%;
    }

    .map-popup {
      position: absolute;
      background: rgba(30, 112, 113, 0.85);
      width: 100%;
      height: 100%;
      @include padding(5px, 30px, 30px, 30px);
      top: 0;
      z-index: 10;

      * {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome and Opera */
      }

      p,
      a,
      span,
      ul li {
        @include font-size(8);
        @include line-height(8);
        font-weight: normal;
      }

      .triggerInfo {
        @include font-size(8);
        @include line-height(8);
        cursor: pointer;
      }

      .partners-section {
        overflow-y: auto;
        height: 418px;

        .grid {
          .grid-item {
            background: #fff;
            @include margin(0, auto, 10px, auto);
            @include padding(15px, 15px, 15px, 15px);

            .plus-minus {
              @extend .center-flex;
              @extend .justify-center;
              @include font-size(7);
              font-weight: bold;
              text-align: center;
              width: 24px;
              height: 24px;
              float: right;
              @include margin(-5px, auto, 0, auto);
              @include border-radius(12px, 12px, 12px, 12px);
              @include transition(0s, 0.2s, all, ease-in-out);
              cursor: pointer;
            }

            .btnMore,
            .btnLess {
              &:hover > .plus-minus {
                background: $whiteGrayColor;
              }
            }
          }
        }

        .grid-sizer,
        .grid-item {
          width: 23.5%;
        }
      }

      #countryNamePartners {
        color: $whiteColor;
        @include margin(10px, 0, 0, 0);
      }

      .partnerName {
        font-weight: bold;
        margin-top: 10px;
      }

      .map-partner-image {
        border: 1px solid $lightGrayColor;
      }

      .closePopup {
        @include font-size(20);
        @include padding(10px, 10px, 10px, 10px);
        position: absolute;
        color: #fff;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
    }

    #accordion {
      width: 100%;
    }

    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      border: none;
      @include border-radius(5px, 5px, 5px, 5px);

      > hr {
        margin-right: 0;
        margin-left: 0;
      }

      > .list-group:first-child {
        .list-group-item:first-child {
          @include border-radius(8px, 8px, 8px, 8px);
        }
      }

      > .list-group:last-child {
        .list-group-item:last-child {
          @include border-radius(8px, 8px, 8px, 8px);
        }
      }

      .accordion img {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    .card-body {
      flex: 1 1 auto;
      padding: $card-spacer-x;
    }

    .card-title {
      margin-bottom: $card-spacer-y;
    }

    .card-subtitle {
      margin-top: -($card-spacer-y / 2);
      margin-bottom: 0;
    }

    .card-text:last-child {
      margin-bottom: 0;
    }

    .card-link {
      &:hover {
        text-decoration: none;
      }

      + .card-link {
        margin-left: $card-spacer-x;
      }
    }

    .card-header {
      padding: $card-spacer-y $card-spacer-x;
      margin-bottom: 5px;
      background: $whiteGrayColor;
      border-bottom: 1px solid $tealHoverColor;

      h5 {
        @include font-size(13);
        @include margin(0, auto, 0, auto);
        @include padding(5px, 5px, 5px, 5px);
        text-align: center;

        a {
          text-decoration: none;
        }
      }

      &:first-child {
        @include border-radius(8px, 8px, 8px, 8px);
      }

      + .list-group {
        .list-group-item:first-child {
          border-top: 0;
        }
      }
    }

    .card-footer {
      padding: $card-spacer-y $card-spacer-x;
      background-color: $lightGrayColor;

      &:last-child {
        @include border-radius(8px, 8px, 8px, 8px);
      }
    }

    .card-header-tabs {
      margin-right: -($card-spacer-x / 2);
      margin-bottom: -$card-spacer-y;
      margin-left: -($card-spacer-x / 2);
      border-bottom: 0;
    }

    .card-header-pills {
      margin-right: -($card-spacer-x / 2);
      margin-left: -($card-spacer-x / 2);
    }

    .card-img-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: $card-img-overlay-padding;
    }

    .card-img {
      width: 100%;
      @include border-radius(0px, 0px, 0px, 0px);
    }

    .card-img-top {
      width: 100%;
      @include border-radius(8px, 8px, 8px, 8px);
    }

    .card-img-bottom {
      width: 100%;
      @include border-radius(8px, 8px, 8px, 8px);
    }

    .card-deck {
      display: flex;
      flex-direction: column;

      .card {
        margin-bottom: $card-deck-margin;

        @media screen and (min-width: $break-small) {
          display: flex;
          flex: 1 0 0%;
          flex-direction: column;
          margin-right: $card-deck-margin;
          margin-bottom: 0;
          margin-left: $card-deck-margin;
        }
      }

      @media screen and (min-width: $break-small) {
        flex-flow: row wrap;
        margin-right: -$card-deck-margin;
        margin-left: -$card-deck-margin;
      }
    }

    .card-group {
      display: flex;
      flex-direction: column;

      > .card {
        margin-bottom: $card-group-margin;
      }
    }

    .card-columns {
      .card {
        margin-bottom: $card-columns-margin;

        @media screen and (min-width: $break-small) {
          display: inline-block;
          width: 100%;
        }
      }

      @media screen and (min-width: $break-small) {
        column-count: $card-columns-count;
        column-gap: $card-columns-gap;
      }
    }

    .mapcontainer {
      position: relative;
      width: 100%;
      min-height: 550px;
      @include margin(0, auto, 0, auto);
      @include padding(0, 0, 0, 0);

      .map {
        width: 100%;

        svg {
          width: 100%;

          path {
            cursor: pointer;
          }
        }

        .mapTooltip {
          position: absolute;
          max-width: 200px;
          display: none;
          background: $grayColor;
          color: $whiteColor;
          @include border-radius(4px, 4px, 4px, 4px);
          @include padding(5px, 8px, 5px, 8px);
          filter: alpha(opacity=90);
          opacity: 0.9;
          z-index: 1000;
        }

        .zoomButton {
          @include font-size(10);
          font-weight: bold;
          position: absolute;
          width: 24px;
          height: 24px;
          top: 0;
          @include margin(0, auto, 0, auto);
          @include padding(5px, 5px, 5px, 5px);
          text-align: center;
          cursor: pointer;
          opacity: 0.5;

          &.zoomReset {
            font-family: "Font Awesome 5 Free";

            &:before {
              content: "\f57c";
            }
          }

          &.zoomIn {
            font-family: "Font Awesome 5 Free";
            top: 25px;

            &:before {
              content: "\f067";
            }
          }

          &.zoomOut {
            font-family: "Font Awesome 5 Free";
            top: 50px;

            &:before {
              content: "\f068";
            }
          }

          &:hover {
            opacity: 1;
          }
        }
      }

      .mapKey {
        position: absolute;
        @include font-size(8);
        @include margin(0, auto, 0, auto);
        @include padding(5px, 5px, 5px, 5px);
        top: 60%;
        left: 0;

        .keyItem {
          @extend .center-flex;
          @include margin(0, 0, 5px, 0);

          .keyColor {
            width: 25px;
            height: 18px;
            @include margin(0, 5px, 0, 0);
          }
        }
      }

      @media screen and (max-width: $break-small) {
        height: 250px;
      }
    }

    .initiatives {
      @extend .center-flex;
      position: absolute;
      top: 0;
      right: 0;

      h5 {
        @include font-size(10);
        @include margin(0, 10px, 10px, 0);
        @include padding(5px, 5px, 5px, 5px);
      }

      .btn {
        background: $lightTealColor;
        border-color: $lightTealColor;
        @include margin(0, 10px, 10px, 0);
        @include padding(5px, 8px, 5px, 8px);
        @include border-radius(20px, 20px, 20px, 20px);
        @include transition(0s, 0.2s, all, ease-in-out);
        opacity: 0.7;

        &:hover {
          background: $tealHoverColor;
          opacity: 1;
        }

        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }

    .popup {
      @include margin(0, auto, 0, auto);
      @include padding(0, 0, 0, 0);

      &.countries {
        background: $darkBlueColor;
        color: $whiteColor;
        position: absolute;
        display: none;
        width: 100%;
        float: none;
        z-index: 9999;
        overflow-x: auto;

        p,
        ul,
        ol,
        h1,
        h2,
        h3 {
          color: $whiteColor;
        }

        .closeBtn {
          position: absolute;
          right: 0;
          cursor: pointer;
          @include padding(30px, 30px, 30px, 30px);
        }

        .popupContent {
          @include padding(40px, 50px, 40px, 40px);

          .media-content {
            vertical-align: middle;
          }
        }

        @media screen and (min-width: $break-small) {
          height: 350px;
        }

        @media screen and (min-width: $break-medium) {
          height: 500px;
        }

        @media screen and (min-width: $break-large) {
          height: 650px;
        }

        .logos {
          @include margin(0, auto, 0, auto);
          @include padding(0, 0, 0, 0);

          img {
            background: $whiteColor;
            @include margin(5px, 5px, 20px, 5px);
          }
        }

        #myModal-footnotes {
          ol li {
            @include font-size(12);
            @include line-height(12);

            a {
              color: $darkBlueColor;
            }
          }
        }
      }
    }
  }

  #news {
    @include padding(30px, 0, 30px, 0);

    img{
      height:200px;
    }

    h4{
      padding-top:10px;
    }

    .news-item {
      width: 47%;
      min-height: 60vh;
      @include margin(0, 10px, 10px, 0);
      @include padding(0, 15px, 15px, 15px);

      @media screen and (max-width: 991px) {
        width: 100%;
        min-height: 55vh;
      }

      @media screen and (max-width: $break-medium) {
        width: 100%;
        min-height: 50vh;
        @include margin(0, 0, 10px, 0);
      }
    }

    .sidebar {
      border-left: 1px solid $tealColor;
      @include padding(0, 30px, 0, 30px);

      .newsFeed {
        @include margin(0, auto, 0, auto);
        @include padding(0, 0, 0, 0);

        .feed-item {
          @include margin(0, auto, 0, auto);
          @include padding(0, 0, 20px, 0);

          h5 {
            @include font-size(10);
            @include line-height(15);
          }

          a {
            text-decoration: none;
          }

          .date {
            font-style: oblique;
          }
        }
      }

      @media screen and (max-width: $break-large) {
        @include padding(0, 15px, 0, 15px);
      }

      @media screen and (max-width: $break-medium) {
        border-left: none;
      }
    }
  }

  .categoryBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 0;
    flex-shrink: 0;

    .category {
      background: $whiteGrayColor;
      width: 23%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      @include margin(0, 10px, 10px, 0);
      @include padding(5px, 5px, 5px, 5px);
      text-align: center;
      @include transition(0s, 0.3s, all, ease-in-out);
      cursor: pointer;

      a {
        width: 100%;
        color: $darkBlueColor;
        @include font-size(12);
        @include padding(45px, 15px, 45px, 15px);
        text-decoration: none;
      }

      &:hover {
        background: $tealColor;

        a {
          color: $whiteColor;
        }
      }

      @media screen and (max-width: $break-large) {
        width: 31%;
      }

      @media screen and (max-width: $break-medium) {
        width: 30%;
      }

      @media screen and (max-width: $break-small) {
        width: 100%;
      }
    }
  }

  .resultsBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-grow: 0;

    .result {
      position: relative;
      background: $whiteGrayColor;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      width: 23%;
      min-height: 248px;
      text-align: center;
      border: none;
      @include margin(0, 10px, 10px, 0);
      @include padding(5px, 5px, 5px, 5px);
      @include transition(0s, 0.3s, all, ease-in-out);
      cursor: pointer;

      h5,
      p,
      a {
        @include transition(0s, 0.3s, all, ease-in-out);
      }

      img {
        @include margin(0, auto, 0, auto);
        @include padding(0, 0, 0, 0);
      }

      h5,
      p,
      a {
        @include transition(0s, 0.3s, all, ease-in-out);
      }

      h5 {
        @include font-size(8);
      }

      p {
        @include font-size(7.5);
      }

      a {
        width: 100%;
        color: $darkBlueColor;
        @include font-size(10);
        @include padding(0, 0, 0, 0);
        text-decoration: none;
      }

      .thumb {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 150px;
        @include margin(0, auto, 10px, auto);
      }

      .tooltip {
        position: absolute;
        width: 225px;
        background: rgba(0, 133, 124, 0.9);
        color: $whiteColor;
        top: 25px;
        left: 55px;
        @include margin(0, 0, 0, 0);
        @include padding(15px, 15px, 15px, 15px);
        @include border-radius(8px, 8px, 8px, 8px);
        @include transition(0, 0.3s, all, ease-in-out);
        @include box-shadow(unquote("0 1px 14px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset"));
        opacity: 0;
      }

      &:hover {
        background: $tealColor;
        overflow: visible;

        h5,
        p,
        a {
          color: $whiteColor;
        }

        .tooltip {
          transition-delay: 2s;
          opacity: 1;
        }
      }

      &:focus,
      &:active {
        border: none;
      }

      .btnhide {
        display: none;
      }

      @media screen and (max-width: $break-large) {
        width: 31%;
      }

      @media screen and (max-width: $break-medium) {
        width: 30%;
      }

      @media screen and (max-width: $break-small) {
        width: 100%;
      }
    }
  }

  .clearFilters {
    @media screen and (max-width: $break-xsmall) {
      width: 50%;
      text-align: center;
    }
  }

  .filterPanel {
    background: $whiteGrayColor;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    @include margin(0, 0, 0, 0);
    @include padding(0, 0, 0, 0);

    .filterTitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: fit-content;
      @include margin(0, 0, 0, auto);
      @include padding(25px, 25px, 25px, 10px);

      p {
        min-width: 75%;
        @include margin(0, auto, 0, auto);
        @include padding(0, 5px, 0, 5px);

        @media screen and (max-width: $break-medium) {
          width: 100%;
        }

        @media screen and (max-width: $break-xsmall) {
          min-width: 100%;
          text-align: left;
          @include margin(0, 0, 0, 0);
          @include padding(0, 25px, 0, 25px);
        }
      }

      @media screen and (max-width: $break-xsmall) {
        @include margin(0, 0, 0, 0);
        @include padding(25px, 25px, 25px, 25px);
      }
    }

    .filterContent {
      max-width: 75%;
      min-width: 75%;
      @include margin(0, 0, 0, auto);
      @include padding(25px, 25px, 25px, 10px);

      p {
        @include font-size(10);
      }

      @media screen and (max-width: $break-large) {
        max-width: 80%;
        min-width: 80%;
        @include padding(25px, 25px, 25px, 0);
      }

      @media screen and (max-width: $break-medium) {
        max-width: 100%;
        min-width: 100%;
        @include margin(0, 0, 0, 0);
        @include padding(25px, 25px, 25px, 0);
      }
    }

    .filter {
      @include font-size(8);
      background: none;
      border: none;

      .formContent {
        justify-content: flex-start;
      }

      .card {
        background: none;
        border: none;

        .card-header {
          background: none;

          h5 {
            @include font-size(10);

            @media screen and (max-width: $break-xsmall) {
              text-align: left;
            }
          }

          a {
            text-decoration: none;

            &:not(.collapsed) {
              i {
                &:before {
                  content: "\f068";
                }
              }
            }
          }

          i {
            position: absolute;
            right: 0;
            @include transition(0s, 0.3s, all, ease-in-out);
          }
        }
      }

      .search {
        @include padding(0.75rem, 1.25rem, 0.75rem, 1.25rem);

        h5 {
          @include font-size(10);
        }
      }
    }
  }

  .modal {
    overflow-y: auto;
    @include transition(0, 0.15s, all, ease-in-out);

    .modalBtn {
      position: absolute;
      @include margin(0, 0, 0, 0);
      @include padding(4px, 10px, 4px, 10px);
      top: 10px;
      right: 45px;
    }

    .close {
      @include font-size(30);
      font-weight: bold;
      cursor: pointer;
      @include margin(-0.75rem, -0.75rem, -0.75rem, auto);
      @include padding(0.25rem, 0.25rem, 0.25rem, 0.25rem);
    }

    .modal-dialog {
      max-width: 75%;

      .thumb {
        display: block;
        width: 100%;
        min-height: 350px;
        max-height: 500px;
        float: left;
        @include margin(0, 10px, 10px, 0);
      }

      .metadata {
        p {
          font-weight: bold;

          &.caption {
            font-weight: normal;
            font-style: oblique;
            @include margin(0, 0, 10px, 0);
          }
        }
      }

      .popupLabel {
        font-weight: normal;
      }

      h3 {
        @include margin(0, 0, 0, 0);
        @include padding(0, 0, 0, 0);
      }

      @media screen and (max-width: $break-medium) {
        width: 100%;
        @include margin(10px, auto, 10px, auto);
      }

      @media screen and (max-width: $break-xsmall) {
        width: 100%;
        @include margin(10px, auto, 10px, auto);
      }
    }

    .modal-content {
      @media screen and (max-width: $break-medium) {
        width: 100%;
        @include margin(10px, auto, 10px, auto);
      }

      @media screen and (max-width: $break-xsmall) {
        width: 100%;
        @include margin(10px, auto, 10px, auto);
      }
    }
  }

  .nav-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    @include margin(0, auto, 15px, auto);

    li {
      a {
        color: $tealColor;
        text-decoration: none;
        @include padding(5px, 10px, 5px, 10px);

        &.active {
          color: $tealColor;
          border-bottom: 4px solid $tealColor;
        }
      }
    }
  }

  .tab-content {
    position: relative;

    .tab-pane {
      position: absolute;

      &.show {
        position: relative;
      }
    }
  }

  #PhotoLibrary {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include margin(0, auto, 0, auto);
    @include padding(0, 0, 0, 0);

    .btnhide {
      display: none;
    }
    &#loginForm {
      max-width: 350px;

      label {
        width: 90%;
      }
    }

    #sortForm {
      width: 100%;
    }

    &#filterForm {
      width: 85%;
      display: block;
      @include margin(0, 0, 0, auto);

      @media screen and (max-width: $break-xsmall) {
        width: 100%;
      }
    }

    &#uploadForm {
      align-items: flex-start;
      justify-content: flex-start;
    }

    input {
      width: 100%;
      @include margin(5px, auto, 5px, auto);
      @include padding(10px, 10px, 10px, 10px);

      &[type="checkbox"] {
        display: none;

        + label.box {
          position: relative;
          width: 18px !important;
          height: 18px !important;
          border: 1px solid $lightGrayColor;
          background: $whiteColor;
          float: left;
          @include margin(0, 8px, 0, 0);
          @include border-radius(3px, 3px, 3px, 3px);
          cursor: pointer;
        }

        &:checked + label.box {
          background: $tealColor;

          &:after {
            font-family: "Font Awesome 5 Free";
            position: absolute;
            top: 0;
            left: 3px;
            content: "\2714";
            @include font-size(8);
            color: $whiteColor;
          }
        }
      }

      &[type="radio"] {
        display: none;

        + label.box {
          position: relative;
          width: 18px !important;
          height: 18px !important;
          border: 1px solid $lightGrayColor;
          background: $whiteColor;
          float: left;
          @include margin(0, 8px, 0, 0);
          @include border-radius(9px, 9px, 9px, 9px);
          cursor: pointer;
        }

        &:checked + label.box {
          background: $tealColor;

          &:after {
            font-family: "Font Awesome 5 Free";
            position: absolute;
            top: 1px;
            left: 3px;
            content: "\f111";
            @include font-size(6);
            color: $whiteColor;
          }
        }
      }
    }

    select {
      width: 100%;
      @include margin(5px, auto, 5px, auto);
      @include padding(10px, 10px, 10px, 10px);
    }

    .formContent {
      @include font-size(8);
      @include line-height(9);
      @include margin(0, 0, 0, 0);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      label {
        width: auto;
      }
    }

    #downloadForm {
      width: 100%;
      @include margin(0, auto, 0, auto);

      .other {
        position: absolute;
        width: 70%;
        @include margin(-25px, 0, 0, 10%);
        @include padding(4px, 4px, 4px, 4px);
      }
    }

    .modal {
      background: rgba(0, 0, 0, 0.7);
    }
  }

  #download {
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    @include margin(0, auto, 0, auto);
    @include padding(0, 0, 0, 0);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include margin(0, 0, 0, 0);

    input,
    select,
    label {
      /* width: 100%; */

      &.other {
        width: 99%;
      }
    }

    label {
      @include margin(0, 0, 0, 0);
    }
  }

  .pagination {
    text-align: center;
    @include margin(0, 0, 0, 0);
    @include padding(0, 0, 0, 0);

    li {
      @include margin(0, 0, 0, 0);
      @include padding(0, 8px, 0, 8px);

      a {
        display: flex;
        width: auto !important;
        height: 25px;
        font-weight: bold;
        text-decoration: none;
        opacity: 1;
        @include padding(4px, 8px, 4px, 8px);

        &:not([href]) {
          cursor: not-allowed;
          font-weight: normal;
          opacity: 0.5;
        }

        &:hover {
          background: $tealColor;
          color: $whiteColor;
          @include border-radius(4px, 4px, 4px, 4px);
        }
      }
    }
  }

  .newsletterSignUp {
    width: 100%;
    height: 300px;

    @media screen and (max-width: $break-small) {
      height: 400px;
    }
  }

  #newsletterSignUp {
    @include padding(30px, 0, 30px, 0);

    form {
      .form-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
      }

      input[type="text"] {
        width: 65%;
      }

      input[type="checkbox"] {
        display: inline-flex;
        width: 20%;
      }

      input[type="submit"] {
        width: 35%;
        @include padding(0.375rem, 0.75rem, 0.375rem, 0.75rem);

        &:focus {
          @include box-shadow(unquote("0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset"));
        }
      }
    }
  }

  .contactFrame {
    width: 100%;
    height: 650px;

    @media screen and (max-width: $break-small) {
      height: 720px;
    }
  }

  form {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include margin(0, auto, 0, auto);
    @include padding(0, 0, 0, 0);

    &#loginForm {
      max-width: 350px;

      label {
        width: 90%;
        display: block;
      }
    }

    &#searchForm {
      flex-direction: row;
      max-width: 100%;
      @include margin(15px, auto, 15px, auto);

      .searchField {
        width: 73%;
        @include margin(0, 10px, 0, 0);
        @media screen and (max-width: $break-small) {
          width: 100%;
          margin-bottom: 0;
        }
      }

      .formContent {
        @include margin(10px, auto, 10px, auto);
      }

      .btn {
        width: 20%;
        @include margin(0, 10px, 0, 0);
        @media screen and (max-width: $break-small) {
          width: 100%;
          margin-top: 0;
        }
      }
    }

    &#editForm {
      @include font-size(9);

      input,
      select,
      textarea {
        @include font-size(9);
        @include margin(5px, 0, 5px, 0);
      }

      .popupLabel {
        @include font-size(9);
      }
    }

    &#sortForm {
      width: 100%;
    }

    &#filterForm {
      width: 85%;
      display: block;
      @include margin(0, 0, 0, auto);

      @media screen and (max-width: $break-xsmall) {
        width: 100%;
      }
    }

    &#uploadForm {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &.contactForm {
      width: 100%;
      max-width: 100%;

      input[type="submit"] {
        width: 25%;
      }
    }

    label {
      display: inline-flex;
      align-items: center;
    }

    input {
      width: 100%;
      @include margin(5px, auto, 5px, auto);
      @include padding(10px, 10px, 10px, 10px);

      &[type="checkbox"] {
        display: none;

        + label.box {
          position: relative;
          width: 18px !important;
          height: 18px !important;
          border: 1px solid $lightGrayColor;
          background: $whiteColor;
          float: left;
          @include margin(0, 8px, 0, 0);
          @include border-radius(3px, 3px, 3px, 3px);
          cursor: pointer;
        }

        &:checked + label.box {
          background: $tealColor;

          &:after {
            font-family: "Font Awesome 5 Free";
            position: absolute;
            top: 0;
            left: 3px;
            content: "\2714";
            @include font-size(8);
            color: $whiteColor;
          }
        }
      }

      &[type="radio"] {
        display: none;

        + label.box {
          position: relative;
          width: 18px !important;
          height: 18px !important;
          border: 1px solid $lightGrayColor;
          background: $whiteColor;
          float: left;
          @include margin(0, 8px, 0, 0);
          @include border-radius(9px, 9px, 9px, 9px);
          cursor: pointer;
        }

        &:checked + label.box {
          background: $tealColor;

          &:after {
            font-family: "Font Awesome 5 Free";
            position: absolute;
            top: 1px;
            left: 3px;
            content: "\f111";
            @include font-size(6);
            color: $whiteColor;
          }
        }
      }
    }

    select {
      width: 100%;
      @include margin(5px, auto, 5px, auto);
      @include padding(10px, 10px, 10px, 10px);
    }

    .formContent {
      @include font-size(8);
      @include line-height(9);
      @include margin(5px, 0, 5px, 0);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  table {
    width: 100%;
    @include margin(0, auto, 0, auto);
    @include padding(0, 0, 0, 0);

    td {
      @include padding(5px, 5px, 5px, 5px);
    }

    &.history {
      width: 100%;
      @include font-size(8);
    }
  }

  #socialBar {
    background: $whiteGrayColor;
    @include padding(45px, 0, 45px, 0);

    h4 {
      @include font-size(10);
      @include padding(0, 0, 15px, 0);

      a {
        color: $darkBlueColor;
        text-decoration: none;

        &:hover {
          color: $tealHoverColor;
        }

        i {
          font-size: 30px;
        }
      }
    }

    i {
      margin-right: 10px;
    }

    .logo {
      max-width: 250px;
    }
  }

  .Childbirth-logo {
    padding-top: 35%;
    @media (max-width: 768px) {
      padding-top: 5%;
    }
  }

  .cardBox {
    @include padding(0, 0, 0, 0);
    @media screen and (max-width: $break-medium) {
      @include margin(20px, auto, 20px, auto);
      @include border-radius(20px, 20px, 20px, 20px);
      box-shadow: none;
    }
    img {
      @media screen and (max-width: $break-medium) {
        @include border-radius(20px, 20px, 0px, 0px);
      }
    }
    .cardBoxContentContainer {
      @include padding(50px, 10px, 50px, 10px);
      @media (max-width: 1300px) {
        @include padding(30px, 10px, 30px, 10px);
      }
      @media (max-width: 1200px) {
        @include padding(20px, 10px, 20px, 10px);
      }
      @media (max-width: 768px) {
        @include padding(10px, 10px, 10px, 10px);
      }
      p {
        a {
          color: $tealColor;
          text-decoration: underline;
        }
      }
      .logo {
        width: 200px;
        @include margin(25px, 0px, 0px, 0px);
        @media (max-width: 768px) {
          text-align: center;
          @include margin(0, auto, 0, auto);
        }
      }
      .cardBoxContent.leftImage {
        @media (min-width: 1500px) {
          width: 550px;
          margin-left: 8.33333333%;
        }
      }
      .cardBoxContent.rightImage {
        @media (min-width: 1500px) {
          width: 550px;
          margin-left: 10%;
        }
        @media (min-width: 1600px) {
          width: 550px;
          margin-left: 20%;
        }
      }
      .cardBoxContent {
        img {
          @media (max-width: 768px) {
            @include margin(0, auto, 0, auto);
          }
        }
        p {
          @media (max-width: 1350px) {
            line-height: normal;
            font-size: 18px;
          }
          @media (max-width: 1200px) {
            line-height: normal;
            font-size: 18px;
          }
          @media (max-width: 1200px) {
            line-height: normal;
            font-size: 17px;
          }
          span {
            color: $tealColor;
          }
        }
      }
    }
  }

  footer {
    @include margin(25px, auto, 0, auto);
    @include padding(20px, 0, 20px, 0);

    &.main {
      border-top: none;
    }

    &.photoLibrary {
      border-top: 1px solid $grayColor;
    }

    p {
      @include font-size(7);
      @include line-height(9);

      @media screen and (min-width: $break-xsmall) and (max-width: $break-small) {
        text-align: center;
      }

      @media screen and (min-width: $break-medium) {
        text-align: left;
      }
    }

    .social-icons {
      text-align: right;

      a {
        @include font-size(20);
        @include margin(0, 0, 0, 10px);
        color: $darkBlueColor;

        i {
          @include font-size(20);
          @include margin(5px, 5px, 5px, 5px);
          @include padding(0, 0, 0, 0);
        }

        &:hover {
          color: $tealColor;
        }
      }

      @media screen and (max-width: $break-small) {
        text-align: center;
      }
    }
  }

  &.modal-open {
    section .container {
      filter: blur(20px);
    }
  }
}

/* SPACERS */
@for $i from 1 through 10 {
  .spacer#{$i * 10} {
    width: $full-width;
    height: $i * 10 + px;
    float: none;
    clear: both;
  }
}

@for $i from 1 through 10 {
  .pad#{$i * 10} {
    padding: $i * 10 + px;
  }
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-lg-pull-6,
.col-md-pull-6 {
  right: 50%;

  @media screen and (max-width: $break-medium) {
    right: auto;
  }
}

.col-lg-push-6,
.col-md-push-6 {
  left: 50%;

  @media screen and (max-width: $break-medium) {
    left: auto;
  }
}

iframe.embed-responsive {
  height: 85%;
  margin-bottom: 40%;
  padding: 20px 50px;

  @media screen and (max-width: $break-large) {
    height: 70%;
    margin-bottom: 35%;
  }

  @media screen and (max-width: $break-small) {
    height: 33%;
    margin-bottom: 0;
    padding: 0;
  }
}

/* HIDDEN PARTS */
.hidden {
  display: none;
}

@media screen and (min-width: $break-large) {
  .hidden-lg {
    display: none !important;
  }
}
@media screen and (min-width: $break-medium) and (max-width: $break-large) {
  .hidden-md {
    display: none !important;
  }
}
@media screen and (min-width: $break-small) and (max-width: $break-medium) {
  .hidden-sm {
    display: none !important;
  }
}
@media screen and (min-width: $break-xsmall) and (max-width: $break-small) {
  .hidden-xs {
    display: none !important;
  }
}

.ot-sdk-show-settings {
  cursor: pointer;
}

.crt-tag {
  display: none !important;
}

// Research Compendium styles from Matter
.compendium-project-section section {
  font-size: 15px;
  line-height: 1.6;
  color: #78797a;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}
.compendium-project-section .container {
  padding: 0;
}
.compendium-project-section h1 {
  margin: 0;
  padding: 0;
  line-height: 0.9;
  font-size: 96px;
}
.compendium-project-section h2 {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  line-height: 1.2;
  font-size: 48px;
  font-weight: normal;
}
.compendium-project-section h1,
h2 {
  color: #022341;
}
.compendium-project-section a {
  text-decoration: none;
  color: inherit;
  font-weight: normal;
}
.compendium-project-section a:hover {
  color: white;
  filter: brightness(110%);
}
.compendium-project-section p {
  margin: 1.25em 0;
}
.compendium-project-section span.indent {
  margin-left: 100px;
}
.content {
  max-width: 1024px;
  margin: auto;
  padding: 0 75px;
}
.slides {
  position: relative;
}

.slide--content--image--wrapper {
  position: relative;
}
.slide--content .play-button img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 150px;
  width: auto;
}

/* Scroll Bar */
.scroll-bar {
  position: fixed;
  opacity: 0;
  right: 50px;
  top: 50%;
  z-index: 4;
  transform: translateY(-50%);
  transition: all 200ms ease;
}
.scroll-bar.active {
  opacity: 1;
}
.scroll-node {
  position: relative;
  display: block;
  height: 25px;
  width: 25px;
  margin-bottom: 75px;
  border-radius: 12px;
  background-color: white;
  border: 1px solid #008980 !important;
  transition: all 200ms ease;
}
.scroll-node:after {
  content: "";
  position: absolute;
  left: 50%;
  right: 50%;
  top: 100%;
  width: 1px;
  height: 75px;
  background-color: #008980;
  z-index: 0;
}
.scroll-node:last-child:after {
  display: none;
}
.scroll-node.active {
  background-color: #008980;
}

/* Pre-View */
.slide {
  position: relative;
  width: 100%;
  height: 200vh;
  /* border: 1px solid red; */
}
.slide--content {
  position: relative;
  height: 100%;
}
.slide--content--text {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-75%, -50%);
}
.slide--content--text h1,
.slide--content--text h2 {
  opacity: 0;
  transform: translateY(20%);
  transition: opacity 500ms ease 300ms, transform 500ms ease 300ms;
}
.slide--content--text p {
  margin-top: 45px;
  margin-bottom: 45px;
  max-width: 360px;
  opacity: 0;
  line-height: 1.8;
  color: #78797a;
  transform: translateY(20%);
  transition: opacity 500ms ease 500ms, transform 500ms ease 500ms;
}
.slide--content--text a {
  padding: 10px 15px;
  font-size: 20px;
  color: white;
  background-color: #008980;
  border: none;
  opacity: 0;
  transform: translateY(20%);
  transition: opacity 500ms ease 700ms, transform 500ms ease 700ms;
}
.slide--content--image {
  position: fixed;
  width: 60%;
  height: auto;
  max-width: 614px;
  right: 50%;
  top: 50%;
  opacity: 0;
  z-index: -2;
  transform: translate(90%, -50%);
  transition: opacity 500ms ease 700ms;
}
.slide--content--image img {
  position: relative;
  height: auto;
  width: 100%;
}
.slide--content--image .compendium--grit-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.slide:not(.slide--title) .slide--content--image:hover {
  cursor: pointer;
}
.slide:not(.slide--title) .slide--content--image:hover img.grey-on-hover {
  filter: grayscale(100%);
}

.slide .scroll-down {
  position: fixed;
  bottom: 7%;
  padding-bottom: 35px;
  right: 50%;
  opacity: 0;
  z-index: 3;
  color: #008980;
  transform: translateX(50%);
  text-transform: uppercase;
  transition: opacity 400ms ease 400ms;
}
.slide .scroll-down.active {
  opacity: 1;
}
.slide .scroll-down::before {
  content: "";
  position: fixed;
  bottom: -50px;
  right: 50%;
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-top: 70px solid #008980;
  transform: translateX(50%);
}
.slide .scroll-down:hover::before {
  transform: translate(50%, 20%);
  transition: transform 200ms ease 0ms;
}

/* In-View */
.slide.view:not(.post-view) .slide--content--text {
  z-index: 2;
}

.slide.view:not(.post-view) .slide--content--text h1,
.slide.view:not(.post-view) .slide--content--text h2 {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms ease 800ms, transform 500ms ease 800ms;
}
.slide.view:not(.post-view) .slide--content--text p {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms ease 1300ms, transform 500ms ease 1300ms;
}
.slide.view:not(.post-view) .slide--content--text a {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms ease 2100ms, transform 500ms ease 2100ms;
}
.slide.view:not(.post-view) .slide--content--image {
  opacity: 1;
  z-index: 1;
  transition: opacity 500ms ease 800ms;
}

/* Post-View */
.slide.post-view:not(.slide--strengthening-health-systems) .slide--content--text h1,
.slide.post-view:not(.slide--strengthening-health-systems) .slide--content--text h2,
.slide.post-view:not(.slide--strengthening-health-systems) .slide--content--text p,
.slide.post-view:not(.slide--strengthening-health-systems) .slide--content--text a {
  opacity: 0;
  transform: translateY(-10%);
  transition: opacity 500ms ease 0, transform 500ms ease 0;
}
.slide.post-view .slide--content--image {
  z-index: -2;
}

/* Slide-Specific */
/* Marquee */
.slide--compendium .slide--content--text h1 {
  opacity: 1;
  transition: opacity 300ms ease 100ms, transform 100ms ease 100ms;
}
.slide--compendium .slide--content--text p {
  transition: opacity 300ms ease 300ms, transform 300ms ease 300ms;
}
.slide--compendium .slide--content--text a {
  transition: opacity 300ms ease 500ms, transform 500ms ease 500ms;
}
.slide--compendium .slide--content--image {
  position: absolute;
  opacity: 1;
  top: 0;
  width: 60vw;
  transform: translate(90%, 0);
}

.slide--compendium.view .slide--content--image {
  position: fixed;
  top: 50%;
  transform: translate(90%, -50%);
}
.slide.slide--compendium.post-view .slide--content--image {
  opacity: 0;
}

/* Women's Experiences */
.slide--womens-experiences .slide--content--text {
  transform: translate(-90%, -29%);
}
.slide--womens-experiences .slide--content--text p {
  max-width: 400px;
}
.slide--womens-experiences .slide--content--image {
  max-width: 740px;
  transform: translate(87%, -50%);
}
.slide--womens-experiences .slide--content--image .play-button img {
  top: 60%;
  left: 44%;
}
.slide.view.slide--womens-experiences:not(.post-view) .slide--content--image {
  transition: opacity 500ms ease 2100ms;
}
/* Equipping Providers */
.slide--equipping-providers .slide--content--text {
  transform: translate(0%, -80%);
}
.slide--equipping-providers .slide--content--text p,
.slide--equipping-providers .slide--content--text a {
  margin-left: 75px;
}
.slide--equipping-providers .slide--content--image {
  max-width: 700px;
  transform: translate(23%, -50%);
}
.slide--content .play-button img {
  top: 45%;
}
.slide.view.slide--equipping-providers:not(.post-view) .slide--content--image {
  transition: opacity 500ms ease 2100ms;
}
/* Strengthening Health Systems */
.slide.post-view.slide--strengthening-health-systems .slide--content--text,
.slide.post-view.slide--strengthening-health-systems .slide--content--image {
  position: absolute;
  opacity: 1;
  z-index: 2;
}
.slide.post-view.slide--strengthening-health-systems .slide--content--text {
  top: 71% !important;
}
.slide.post-view.slide--strengthening-health-systems .slide--content--image {
  top: 73% !important;
  width: 60vw;
}
.slide.post-view.slide--strengthening-health-systems h2,
.slide.post-view.slide--strengthening-health-systems p,
.slide.post-view.slide--strengthening-health-systems a {
  opacity: 1;
  transform: translateY(0);
}
.slide--strengthening-health-systems .slide--content--text {
  transform: translate(-120%, -50%);
}
.slide--strengthening-health-systems .slide--content--text p {
  max-width: 395px;
}
.slide--strengthening-health-systems .slide--content--image {
  transform: translate(90%, -50%);
  width: 60vw;
}

/* Modals */
.modal--compendium {
  position: fixed;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #008980;
  z-index: -1;
}
.modal--compendium.active {
  opacity: 1;
  z-index: 5;
}
.modal--compendium--close {
  position: fixed;
  top: 20px;
  right: 50px;
  font-size: 28px;
  color: white;
  z-index: 5;
}
.modal--compendium--close:hover {
  cursor: pointer;
}
.modal--compendium--video-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal--compendium--video-wrapper iframe {
  width: 60vw;
  height: calc(60vw / 1.7);
}

/* Large Screens */
@media (min-width: 1444px) {
  .compendium-project-section h1 {
    font-size: 100px;
  }
  .compendium-project-section p {
    font-size: 18px;
  }
  .slide--content--image {
    max-width: 760px;
  }

  /* Slide Specific */
  .slide--title p {
    max-width: 480px;
  }
  .slide--compendium .slide--content--image {
    transform: translate(90%, -9%);
  }
  .slide--compendium.view .slide--content--image {
    transform: translate(90%, -50%);
  }
  .slide--womens-experiences .slide--content--text p {
    max-width: 400px;
  }
  .slide--womens-experiences .slide--content--image {
    max-width: 840px;
  }
  .slide--equipping-providers .slide--content--image {
    max-width: 800px;
  }
}

/* Tablet */
@media (max-width: 1024px) {
  /* General Responsiveness */
  .scroll-bar {
    right: 15px;
  }
  .scroll-down {
    bottom: 7%;
  }
  .slide .scroll-down::before {
    bottom: -25px;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 40px solid #008980;
  }
  .compendium-project-section h1,
  .compendium-project-section h2,
  .compendium-project-section p {
    text-align: left;
  }
  .compendium-project-section h1 {
    margin: 0;
    padding: 0;
    line-height: 0.9;
    font-size: 42px;
  }
  .compendium-project-section h2 {
    font-size: 36px;
  }
  .compendium-project-section p {
    font-size: 14px;
  }
  span.indent {
    margin-left: 50px;
  }
  .slide--content--text {
    width: 80vw;
    transform: translate(-50%, -20%);
  }
  .slide--content--image {
    width: 100%;
    transform: translate(50%, -75%);
  }

  /* Slide Specific */
  /* Title */
  .slide--title .slide--content--text {
    transform: translate(-50%, -25%);
  }
  .slide--title .slide--content--image {
    transform: translate(50%, 0);
  }
  .slide--title.view .slide--content--image {
    transform: translate(50%, -65%);
  }
  /* Women's Experiences */
  .slide--womens-experiences .slide--content--text {
    transform: translate(-50%, 60%);
  }
  .slide--womens-experiences .slide--content--image {
    transform: translate(50%, -75%);
  }
  /* Equipping Providers */
  .slide--equipping-providers .slide--content--text {
    transform: translate(-50%, 70%);
  }
  .slide--equipping-providers .slide--content--text p,
  .slide--equipping-providers .slide--content--text a {
    margin-left: 0;
  }
  .slide--equipping-providers .slide--content--image {
    transform: translate(50%, -75%);
  }
  /* Strengthening Health Systems */
  .slide--strengthening-health-systems .slide--content--text {
    transform: translate(-50%, 20%);
  }
  .slide--strengthening-health-systems .slide--content--text p {
    max-width: 360px;
  }
  .slide.post-view.slide--strengthening-health-systems .slide--content--image {
    transform: translate(50%, -110%);
    width: 110vw;
  }

  /* Modals */
  .modal--compendium--video-wrapper iframe {
    width: 100vw;
    height: calc(100vw / 1.7);
  }
}
@media (max-width: 767px) {
  /* General Responsiveness */
  .content {
    padding: 75px 0;
  }

  /* Slide Specific */
  /* Title */
  .slide--title .slide--content--text {
    transform: translate(-50%, -20%);
  }
  .slide--title .slide--content--image {
    transform: translate(50%, 0%);
  }
  .slide--title.view .slide--content--image {
    transform: translate(50%, -80%);
  }
  /* Women's Experiences */
  .slide--womens-experiences .slide--content--text {
    transform: translate(-50%, -10%);
  }
  .slide--womens-experiences .slide--content--image {
    transform: translate(50%, -100%);
  }
  /* Equipping Providers */
  .slide--equipping-providers .slide--content--text {
    transform: translate(-50%, 5%);
  }
  .slide--equipping-providers .slide--content--text p,
  .slide--equipping-providers .slide--content--text a {
    margin-left: 0;
  }
  .slide--equipping-providers .slide--content--image {
    transform: translate(50%, -90%);
  }
  /* Strengthening Health Systems */
  .slide--strengthening-health-systems .slide--content--text {
    transform: translate(-50%, -10%);
  }
  .slide--strengthening-health-systems .slide--content--text p {
    max-width: 360px;
  }
  .slide--strengthening-health-systems .slide--content--image {
    transform: translate(50%, -110%);
    width: 110vw;
  }
}

/* PASS THE STORK PAGE */

#passthestork-container {
  background: #f3f3f3;
  padding-top: 30px;
}

#passthestork-carousel {
  height: auto !important;
}

.carousel-text {
  background: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  padding: 20px;
}

.carousel-text p {
  color: black;
}

.carousel-quote {
  margin-bottom: 5px;
  font-weight: bold !important;
  font-size: 20px;
}

.carousel-person {
  line-height: 1.2;
  font-size: 18px;
}

.carousel-link {
  text-decoration: none !important;
}

.carousel-button {
  color: #00857c !important;
  font-size: 72px !important;
}

.teal-bg {
  background: #00857c !important;
}

.passthestork-content {
  text-align: center;
  position: relative;
}

.passthestork-body {
  padding: 70px 50px 70px 50px;
}

.passthestork-text {
  font-weight: 500;
  color: white;
  line-height: 1.9;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0px;
}

.passthestork-text span a {
  color: white;
  font-weight: normal;
}

.passthestork-download-wrapper {
  position: absolute;
  bottom: -35px;
  left: 50%;
}

.passthestork-button {
  text-decoration: none;
  font-weight: bold !important;
  font-size: 20px;
  padding: 25px 35px;
  border-radius: 7px;
  transition-duration: 0.3s;

  &.passthestork-download {
    position: relative;
    left: -50%;
    border: 2px solid white;
    background: white;
    color: $tealColor;
  }

  &.passthestork-landing-link {
    border: 2px solid $darkBlueColor;
    background: $darkBlueColor;
    color: white;
    display: table;
    margin: 60px auto 0 auto;
  }
}

.passthestork-button:hover {
  background: transparent;
  font-weight: bold;
  cursor: pointer;

  &.passthestork-download {
    color: white;
    border: 2px solid white;
  }

  &.passthestork-landing-link {
    color: $darkBlueColor;
    border: 2px solid $darkBlueColor;
  }
}

.passthestork-cover-img {
  width: 100%;
  height: auto;
}

body #share-box {
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  width: 75px;
  top: 75px;
  left: -7px;
  z-index: 9999;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 0px;
}

body #share-box .share-icons {
  list-style: none;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

body #share-box .share-icons li {
  height: 40px;
  width: 40px;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.25s;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-delay: 0;
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-delay: 0;
  cursor: pointer;
}

@media (max-width: 690px) {
  .passthestork-download-wrapper {
    bottom: -40px;
  }

  .passthestork-download {
    font-size: 18px;
    padding: 15px;
    line-height: 1.5;
  }

  .passthestork-body {
    padding: 40px 0px 80px 0px;
  }

  body #share-box {
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    padding: 20px 0;
  }

  ul.share-icons li {
    display: inline-block;
  }
}

/* Pass the Stork Toolkit */

.pts-tk-hero-wrapper {
  background-image: url("/images/pass-the-stork/pts-hero-img.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;

  .pts-tk-hero-inside {
    position: relative;
    padding: 120px 400px 180px 0px;

    @media (max-width: 991px) {
      padding: 120px 140px 120px 0px;
    }

    h1 {
      line-height: 4rem;
      font-weight: bold;

      @media screen and (max-width: $break-medium) {
        line-height: 3.5rem;
        text-align: left;
      }
    }
  }

  @media only screen and (max-width: 870px) {
    .pts-tk-hero-inside {
      h1 {
        display: none;
      }
    }
  }
}

.pts-hero-text-mobile {
  display: none;
  text-align: center;

  @media only screen and (max-width: 870px) {
    display: block;
  }
}

.pts-tk-intro-wrapper {
  .pts-tk-intro-inside {
    position: relative;
    padding: 50px 0px;

    .pts-tk-intro-text {
      font-size: 20px;
      line-height: 1.4;
      @media screen and (max-width: $break-medium) {
        font-size: 18px;
        text-align: left;
      }
    }

    .pts-tk-steps-button {
      position: absolute;
      background: $tealColor;
      padding: 25px 0;
      width: 60%;
      color: white;
      font-size: 22px;
      text-decoration: none;
      text-align: center;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: -33px;

      @media screen and (max-width: $break-medium) {
        width: 80%;
      }
    }
  }
}

.pts-ty-steps-wrapper {
  position: relative;
  padding: 110px 0;

  p {
    @media screen and (max-width: $break-small) {
      text-align: left;
    }
  }

  .pts-ty-step-row {
    display: block;
    padding: 0px 140px;

    @media (max-width: 991px) {
      padding: 0px;
    }

    @media screen and (max-width: $break-small) {
      padding: 0px 25px;
    }

    .pts-ty-step-num {
      background: $tealColor;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      padding-top: 7px;
      color: white;
    }

    .pts-ty-step-title {
      margin-left: 10px;
    }

    .pts-ty-step-title-wrapper {
      display: flex;

      .pts-ty-step-num.bullhorn {
        font-size: 15px;
      }

      .pts-ty-step-title-text-wrapper {
        margin-left: 10px;

        .pts-ty-step-title {
          margin-left: 0;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    .pts-tk-step-content {
      padding: 40px 20px 40px 20px;
      background: rgb(240, 241, 242);
      margin-top: 15px;

      .pts-tk-step-text-wrapper {
        display: flex;

        i {
          padding: 3px 20px 0px 7px;
          color: $tealColor;
        }

        .pts-tk-step-text {
          p {
            line-height: 1.2;
            margin-bottom: 2px;
          }
        }

        & + .pts-tk-step-text-wrapper {
          margin-top: 20px;
        }
      }
    }

    & + .pts-ty-step-row {
      margin-top: 40px;
    }
  }
}

.pts-tk-steps-full-width {
  position: relative;

  .pts-tk-footer-img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45%;

    @media only screen and (max-width: 880px) {
      position: relative;
      width: 100%;
    }
  }
}

.pts-tk-social-img {
  padding: 20px;
}

.pts-tk-close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: $tealColor;
  font-size: 24px;
}

/* END PASS THE STORK PAGE */

.bold {
  font-family: "Invention Black";
}

/* Customizing confirm modal */
.modal-dialog.confirm-modal {
  width: 700px !important;

  @media screen and (max-width: 740px) {
    width: 90% !important;
  }

  .modal-content {
    border-radius: 20px !important;

    .modal-header {
      border-bottom: none !important;
      margin-top: 10px !important;

      button {
        display: none;
      }
    }

    .modal-footer {
      border-top: none !important;
    }

    .modal-body {
      padding: 0 1rem;
      p {
        @media screen and (max-width: 640px) {
          text-align: left !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .story-one-img {
    order: 1;
  }

  .story-one-text {
    order: 2;
  }
}

.footer-social-row {
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  #socialBar img.logo {
    margin: 0 auto 30px auto;
  }

  .footer-social-row {
    margin-top: 30px !important;
  }
}

#media {
  background: $whiteGrayColor;
  @include padding(30px, 0, 30px, 0);
  h4{
    @include margin(0, 0, 0, 0);
    padding-bottom: 10px;
    padding-top: 10px;

    a {
      text-decoration: none;
    }
  }

  p {
    @include font-size(9);
    @include line-height(11);
    color: $grayColor;
    @include margin(0, 0, 0, 0);
    @include padding(5px, 10px, 25px, 0px);
  }
}

body #map #mapPopup.map-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 144, 135, 0.25); 
  background-color: white; 
  color: white;
  z-index: 9999; /* Bring it above all other elements */
  display: none; /* Initially hidden */
  overflow-y: auto; /* Enable scrolling if content exceeds height */
  padding: 20px;
  box-sizing: border-box;
}

body #map #mapPopup.map-popup {
  background-image: url('/images/With green overlayBackground Map copy@2x.png');
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating */
  width: 100%; /* Full width of the page */
  height: 100%; /* Full height of the viewport */
  position: fixed; /* Keeps the takeover fixed on the screen */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensures it is above other elements */
  display: none; /* Initially hidden */
  overflow: auto; /* Adds scrolling if content overflows */
}



body #map #mapPopup.map-popup h2 {
  margin-top: 20px;
  text-align: center;
  font-size: 37pt;
  color: $tealColor;
}

body #map #mapPopup.map-popup .closePopup {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px;
  cursor: pointer;
  color: #0c2340;
}

body #map #mapPopup.map-popup .partners-section {
  margin-top: 30px;
}

body #map #mapPopup.map-popup .partners-section {
  overflow-y: hidden;
  height: auto;
}

.key-indicators {
  display: flex;
  align-items: center; /* Vertically align items */
  margin: 20px 0;
  justify-content: flex-start; /* Align to the left */
  gap: 20px; /* Add space between indicators */
  color: #0c2340;
  font-size: 17pt;
}

.indicator {
  display: flex;
  align-items: center;
}

.indicator-icon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.indicator-text {
  font-size: 15pt;
  line-height: 1.2;
}

.partners-section {
  display: block; /* Ensure section displays as a block element */
}

.country-partners {
  display: flex; /* Enable flexbox for the container */
  flex-wrap: wrap; /* Wrap items to the next row if necessary */
  gap: 20px; /* Add space between items */
  justify-content: left; 
  margin-top: 20px; /* Add some spacing between sections */
}

#map #mapPopup .partner-item {
  flex: 1 1 calc(25% - 20px); /* Each item takes 25% of the row width, minus gap space */
  max-width: calc(25% - 20px); /* Ensure max-width for 4 items per row */
  text-align: center; /* Center-align content */
}

#map #mapPopup .partner-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px; /* Optional: Rounded corners for images */
}

#map #mapPopup .partner-item .partnerName {
  font-size: 17pt;
  font-weight: bold;
  color: #0c2340; 
  margin-bottom: 10px;
}

#map #mapPopup .partner-item {
  perspective: 1000px; /* Enable 3D perspective for the flip effect */
  width: 100%; /* Ensure cards take full width inside their column */
  height: 300px; /* Set a consistent height for all cards */
  position: relative; /* Required for flipping */
}

#map #mapPopup .partner-item-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;
}

#map #mapPopup .partner-item:hover .partner-item-inner,
#map #mapPopup .partner-item.flipped .partner-item-inner {
  transform: rotateY(180deg); /* Flip the card */
}

#map #mapPopup .partner-item-front,
#map #mapPopup .partner-item-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: card shadow */
  border-radius: 8px;
  background: #fff; /* Optional: background color */
  color: #333; /* Optional: text color */
}

#map #mapPopup .partner-item-front img{
  width:200px;
}

#map #mapPopup .partner-item-back {
  transform: rotateY(180deg); /* Initially flipped */
  background: #fff; /* Background for the back */
  display: flex;
  flex-direction: column; /* Stack the text vertically */
  align-items: flex-start; /* Align text to the left */
  justify-content: flex-start; /* Align items to the top */
  padding: 20px; /* Add padding for spacing */
  border-radius: 8px; /* Optional: rounded corners */
  color: #333; /* Text color */
  line-height: 1.5; /* Improve readability */
  gap: 10px; /* Spacing between lines */
  box-sizing: border-box; /* Ensure padding doesn't overflow */
  height: 100%; /* Ensure it covers the entire card */
}

#map #mapPopup .partner-item-back .partnerName{
  text-align: left;
}


#map #mapPopup .partner-item-back .program-desc {
  color: $tealColor;
  margin: 0; /* Remove default margin for paragraphs */
  font-size: 14pt; /* Adjust text size */
  line-height: 1.5; /* Improve readability */
  text-align: left;
}


#map #mapPopup .slide-navigation{
  position: absolute;
    bottom: 40px;
    left: 50%;
}

#map #mapPopup .carousel-control-prev,
#map #mapPopup .carousel-control-next {
    position: absolute;
    bottom: -30px; /* Adjust this value based on your layout */
    left: 50%;
    transform: translateX(-120%);
    background-color: #00857c; /* Teal */
    border-radius: 50%;
    color: white;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#map #mapPopup .carousel-control-next {
    transform: translateX(20%);
}


#map #mapPopup .carousel-control-prev-icon,
#map #mapPopup .carousel-control-next-icon {
    width: 15px;
    height: 15px;
}


#map #mapPopup  .status-icon {
  position: absolute;
  bottom: 10px;
  right: 0;
  transform: translateX(-50%);
  text-align: center;
  z-index: 10;
}

#map #mapPopup  .status-icon img {
  width: 30px; /* Adjust size as needed */
  height: auto;
}

.partnersMobileaccordion{
  width:100%
}

 .card{
  margin-bottom: 10px;
  border:none;
}

.card-header{
  border-bottom:none;
}

.card-body{
  background-color: rgba(0, 0, 0, 0.03);
}

.card-header a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-weight: bold;
  color: #333;
  padding: 10px;
}

 .card-header a .caret {
  display: inline-block;
    width: 16px;
    height: 16px;
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-color: #5ABABA; /* Use the teal color from the image */
    transform: rotate(-135deg);
    margin-left: 8px;
    transition: transform 0.3s ease;
}

.card-header a.collapsed .caret {
  transform: rotate(45deg); /* Rotate when collapsed */
}

.card-body{
  padding: 0.50rem;
}

/* Target only inner accordions and their cards */
.card-body .accordion .card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.partner-card {
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #ffffff;
}

.partner-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.partner-logo img {
  width: 110px;
  object-fit: contain;
  margin-right: 15px;
}

.partner-title h3 {
  margin: 0;
  font-size: 21px;
  font-weight: bold;
  color: #0c2340;
}

.partner-programs {
  font-size: 18px;
  color: #00857c;
  line-height: 1.5;
}

.partner-programs .program-item {
  margin-bottom: 10px;
}

#accordion .status-icon {
  margin-top: 10px;
    background: #E0F4ED99;
    padding: 2px 10px 10px 10px;
    border-radius: 5px;
    width: 230px;
    color:#082749;
    font-weight: 700;
}


#accordion .icon-text {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px; /* Optional for spacing */
}

#accordion .icon-text img {
  width: 25px; /* Adjust size as needed */
  height: 25px;
}

#accordion .icon-text span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}

/* Prevent sudden margin collapse or spacing shifts */
.card {
  margin-bottom: 0;
}

.card-header {
  scroll-margin-top: 20px; /* Align headers properly after scrolling */
}

.collapse {
  transition: none !important;
}


/* Color Palette */
.whiteColor { color: $whiteColor; }
.offWhiteColor { color: $offWhiteColor; }
.tealColor { color: $tealColor; }
.lightTealColor { color: $lightTealColor; }
.tealHoverColor { color: $tealHoverColor; }
.lightBlueColor { color: $pastelBlueColor; }
.blueColor { color: $vistaBlueColor; }
.richBlueColor { color: $richBlueColor; }
.darkBlueColor { color: $darkBlueColor; }
.limeColor { color: $limeColor; }
.lemonColor { color: $lemonColor; }
.grayColor { color: $grayColor; }
.lightGrayColor { color: $lightGrayColor; }
.whiteGrayColor { color: $whiteGrayColor; }
.redColor { color: $redColor; }

/* Backgrounds */
.whiteColorBkgd { background-color: $whiteColor; }
.offWhiteColorBkgd { background-color: $offWhiteColor; }
.tealColorBkgd { background-color: $tealColor; }
.lightTealColorBkgd { background-color: $lightTealColor; }
.tealHoverColorBkgd { background-color: $tealHoverColor; }
.lightBlueColorBkgd { background-color: $pastelBlueColor; }
.blueColorBkgd { background-color: $vistaBlueColor; }
.richBlueColorBkgd { background-color: $richBlueColor; }
.darkBlueColorBkgd { background-color: $darkBlueColor; }
.limeColorBkgd { background-color: $limeColor; }
.lemonColorBkgd { background-color: $lemonColor; }
.grayColorBkgd { background-color: $grayColor; }
.lightGrayColorBkgd { background-color: $lightGrayColor; }
.whiteGrayColorBkgd { background-color: $whiteGrayColor; }

.comp-btn {
  font-weight: bold !important;
  border-radius: 25px !important;
  padding: 10px 30px !important;
}

.comp-line {
  width: 45%;
  height: 2px;
  margin-bottom: 15px;

  &.teal {
    background-color: rgb(0, 205, 179);
  }

  &.white {
    background-color: white;
  }

  &.purple {
    background-color: rgb(102, 137, 224);
  }
}

.comp-heading {
  font-size: 30px;
  padding-top: 0;
  line-height: 2.3rem;
}

.comp-body {
  font-size: 20px;
}

.video-wrapper {
  cursor: pointer;
  position: relative;
  -webkit-box-shadow: 0 0 45px #666;
  box-shadow: 0 0 25px #666;
  width: 100%;
  z-index: 2;
  transition-duration: 0.3s;

  &:hover {
    .video-layover {
      opacity: 0.4;
    }

    -webkit-box-shadow: 0 0 45px rgb(59, 59, 59);
    box-shadow: 0 0 45px rgb(67, 67, 67);
  }

  @media only screen and (max-width: 577px) {
    margin: 0 20px;
  }

  .video-layover {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 23, 57);
    position: absolute;
    left: 0;
    top: 0;

    opacity: 0;
    transition-duration: 0.3s;
  }

  img {
    width: 100%;
    height: auto;
  }

  .video-content {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 20px;
    background-color: rgba(0, 23, 57, 0.5);
    padding: 30px;
    border-radius: 20px;

    @media only screen and (max-width: 767px) {
      width: 80%;
      transform: translate(-50%, -50%);
      left: 50%;
    }

    img {
      width: 75px;

      @media only screen and (max-width: 641px) {
        width: 50px;
      }
    }

    div {
      color: white;

      .video-title {
        font-size: 32px;
        font-weight: bold;
        transition-duration: 0.3s;
        line-height: 1.1;
        text-align: left;

        @media only screen and (max-width: 641px) {
          font-size: 22px;
          margin-bottom: 5px;
        }

        @media only screen and (max-width: 470px) {
          font-size: 20px;
        }
      }

      .video-subtitle {
        font-size: 26px;
        margin-bottom: 0;
        text-align: left;

        @media only screen and (max-width: 641px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 470px) {
          font-size: 16px;
        }
      }
    }
  }

  .video-offset-square {
    height: 30px;
    width: 30px;
    position: absolute;
    background-color: teal;
    left: -30px;
    bottom: -30px;
  }
}

.dot-array {
  background-image: url('/images/2022-compendium/dot-array.svg');
  background-size: 400px 400px;
  position: absolute;
  z-index: 1;

  &.carousel-dots-1 {
    width: 345px;
    height: 225px;
    top: 25px;
    left: 48px;

    @media only screen and (max-width: 1199px) {
      width: 266px;
      top: 105px;
      left: 26px;
    }

    @media only screen and (max-width: 830px) {
      width: 226px;
      top: 46px;
      left: -76px;
      height: 133px;
    }

    @media only screen and (max-width: 440px) {
      left: -136px;
    }

    &.no-image {
      width: 146px;
      height: 250px;
      left: -12px;
      top: 109px;

      @media only screen and (max-width: 991px) {
        left: -166px;
      }
    }
  }
  &.carousel-dots-2 {
    width: 235px;
    height: 113px;
    right: 0;
    bottom: 25px;
    @media only screen and (max-width: 1199px) {
      right: -82px;
      bottom: 0px;
    }

    @media only screen and (max-width: 830px) {
      display: none;
    }

    &.no-image {
      width: 165px;
      height: 242px;
      right: -90px;
      top: 0;

      @media only screen and (max-width: 991px) {
        right: -185px;
      }
    }
  }
}

.comp-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
  overflow-x: hidden;

  .comp-row {
    margin-top: 120px;
    margin-left: 0;
    margin-right: 0;

    @media only screen and (max-width: 565px) {
      margin-top: 50px;
    }
  }

  .comp-header {
    position: relative;
    box-shadow: 0 6px 25px -2px #666666;

    .heading-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 80px;

      @media only screen and (max-width: 1535px) {
        top: 20px;
      }

      @media only screen and (max-width: 991px) {
        width: 100%;
        max-width: 100%;
        right: 0;
      }

      @media only screen and (max-width: 918px) {
        top: 10px;
      }

      @media only screen and (max-width: 400px) {
        top: 0px;
      }

      h1 {
        text-align: right;
        color: white;
        font-size: 72px;
        font-weight: bold;
        line-height: 5rem;

        @media only screen and (max-width: 991px) {
          line-height: 1;
          width: 50%;
          float: right;
        }

        @media only screen and (max-width: 918px) {
          font-size: 40px;
        }

        @media only screen and (max-width: 521px) {
          font-size: 24px;
        }

        span {
          font-size: 90px;
          line-height: 1;
          background-color: rgb(0, 23, 57);
          padding: 12px;
          font-family: 'Invention Black';
          display: inline-block;
          margin-top: 20px;

          @media only screen and (max-width: 918px) {
            font-size: 60px;
            padding: 6px;
          }

          @media only screen and (max-width: 521px) {
            font-size: 30px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .comp-section-one {
    position: relative;
    padding-top: 100px;

    @media only screen and (max-width: 565px) {
      padding-top: 50px;
    }

    .first-row {
      div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &.left-col {
          padding-right: 100px;

          @media only screen and (max-width: 1200px) {
            padding-right: 30px;
          }

          h2 {
            text-align: left;
          }
        }

        &.right-col {
          p {
            padding-top: 20px;
            line-height: 1.7;
            text-align: left;
          }

          a {
            margin-top: 20px;
          }
        }
      }
    }

    .second-row {
      .second-row-card {
        text-align: center;
        z-index: 2;

        @media only screen and (max-width: 767px) {
          margin-bottom: 30px;
        }

        p {
          font-weight: bold;
        }

        .card-image-wrapper {
          border-radius: 50%;
          padding: 20px;
          width: 155px;
          height: 155px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 20px auto;

          &.one {
            background-color: teal;
          }

          &.two {
            background-color: rgb(0, 23, 57);
          }

          &.three {
            background-color: rgb(94, 204, 179);
          }

          img {
            width: 100px;
            height: 100px;
          }
        }

        .teal-text {
          margin-bottom: 0;
          text-transform: uppercase;
          font-size: 20px;
        }

        .black-text {
          margin: auto;
          font-size: 18px;
        }
      }
    }

    .third-row {
      margin-top: 130px;

      @media only screen and (max-width: 565px) {
        margin-top: 50px;
      }
    }

    .fourth-row {
      .left-col {
        @media only screen and (max-width: 991px) {
          order: 2;
        }
      }
      .right-col {
        h2 {
          text-align: left;
        }

        p {
          text-align: left;
        }
      }
    }

    .section-one-dots-1 {
      height: 192px;
      width: 415px;
      top: 443px;
      left: -86px;

      @media only screen and (max-width: 991px) {
        top: 584px;
      }

      @media only screen and (max-width: 767px) {
        top: 963px;
        width: 194px;
        height: 374px;
      }

      @media only screen and (max-width: 415px) {
        top: 1063px;
        left: -135px;
      }
    }

    .section-one-dots-2 {
      height: 512px;
      width: 415px;
      top: 808px;
      left: 757px;

      @media only screen and (max-width: 991px) {
        top: 897px;
        left: 527px;
        width: 235px;
        height: 235px;
      }

      @media only screen and (max-width: 767px) {
        // top: 1779px;
        // left: 324px;
        top: 1608px;
        left: 301px;
      }

      @media only screen and (max-width: 575px) {
        top: 1599px;
        left: 369px;
        height: 160px;
        width: 160px;
      }

      @media only screen and (max-width: 560px) {
        top: 1373px;
        left: 390px;
      }

      @media only screen and (max-width: 522px) {
        top: 1417px;
        left: 345px;
      }

      @media only screen and (max-width: 459px) {
        top: 1496px;
        left: 260px;
      }

      @media only screen and (max-width: 428px) {
        top: 1535px;
        left: 240px;
      }

      @media only screen and (max-width: 410px) {
        top: 1566px;
        left: 225px;
      }

      @media only screen and (max-width: 402px) {
        top: 1600px;
        left: 215px;
      }

      @media only screen and (max-width: 383px) {
        top: 1597px;
        left: 198px;
      }

      @media only screen and (max-width: 357px) {
        top: 1594px;
        left: 180px;
      }

      @media only screen and (max-width: 334px) {
        top: 1611px;
        left: 166px;
      }
    }
  }

  .comp-carousel {
    height: 375px;
    z-index: 2;

    @media only screen and (max-width: 830px) {
      height: 317px;
    }

    @media only screen and (max-width: 383px) {
      height: 335px;
    }

    .carousel-inner {
      height: 100%;
      .carousel-item {
        height: 100%;
        overflow: hidden;

        &.dark-blue-background {
          background-color: rgb(0, 23, 57);

          .slide-content {
            .slide-main {
              .slide-name {
                background-color: rgb(0, 205, 179);
              }
            }
          }
        }

        &.teal-background {
          background-color: rgb(0, 205, 179);

          .slide-content {
            .slide-main {
              .slide-heading {
                color: rgb(0, 23, 57);
                font-weight: bold;
              }

              .slide-title {
                color: rgb(0, 25, 57);
              }

              .slide-name {
                background-color: rgb(0, 25, 57);
              }
            }
          }
        }

        .slide-content {
          position: relative;
          height: 100%;

          .slide-headshot {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 444px;
            z-index: 2;

            @media only screen and (max-width: 1200px) {
              width: 323px;

              &.carmen-villar {
                width: 300px;
              }
            }

            @media only screen and (max-width: 830px) {
              width: 256px;

              &.carmen-villar {
                width: 235px;
              }
            }

            @media only screen and (max-width: 592px) {
              left: -40px;
            }

            @media only screen and (max-width: 440px) {
              width: 220px;

              &.carmen-villar {
                width: 193px;
              }

              &.julie-fleischer {
                width: 205px;
              }
            }

            @media only screen and (max-width: 400px) {
              &.julie-fleischer {
                width: 190px;
              }
            }
          }

          .slide-main {
            position: absolute;
            left: 444px;
            top: 70px;
            z-index: 2;

            @media only screen and (max-width: 1200px) {
              left: 307px;
              top: 33px;
            }

            @media only screen and (max-width: 767px) {
              &.small-text {
                width: 70%;
                top: 10px;
              }
            }

            @media only screen and (max-width: 725px) {
              &.small-text {
                width: 65%;
              }
            }

            @media only screen and (max-width: 685px) {
              &.small-text {
                width: 60%;
              }
            }

            @media only screen and (max-width: 830px) {
              left: 243px;
            }

            @media only screen and (max-width: 592px) {
              left: 164px;
            }

            @media only screen and (max-width: 440px) {
              left: 140px;
              top: 18px;
              width: 60%;
            }

            @media only screen and (max-width: 432px) {
              left: 124px;
            }

            .slide-heading {
              color: rgb(0, 205, 179);
              font-size: 22px;
              line-height: 1.2;
              margin-bottom: 35px;
              text-align: left;

              @media only screen and (max-width: 991px) {
                &.small-text {
                  font-size: 18px;
                }
              }

              @media only screen and (max-width: 767px) {
                &.small-text {
                  margin-bottom: 12px;
                }

                font-size: 18px;
                margin-bottom: 12px;
              }

              @media only screen and (max-width: 725px) {
                &.small-text {
                  font-size: 16px;
                }
              }

              @media only screen and (max-width: 592px) {
                font-size: 20px;
              }

              @media only screen and (max-width: 432px) {
                &.small-text {
                  font-size: 15px;
                }
              }

              @media only screen and (max-width: 355px) {
                &.small-text {
                  font-size: 14px;
                }
              }
            }

            .slide-name {
              font-size: 20px;
              color: white;
              display: inline;
              padding: 0 10px;
              text-align: left;

              @media only screen and (max-width: 991px) {
                &.small-text {
                  font-size: 16px;
                }
              }

              @media only screen and (max-width: 725px) {
                &.small-text {
                  font-size: 14px;
                }
              }

              @media only screen and (max-width: 830px) {
                font-size: 18px;
              }

              @media only screen and (max-width: 592px) {
                font-size: 16px;
                margin-left: 50px;
              }

              @media only screen and (max-width: 440px) {
                font-size: 14px;
              }
            }

            .slide-title {
              color: white;
              font-size: 17px;
              margin-top: 10px;
              text-align: left;
              line-height: 1.5;

              @media only screen and (max-width: 991px) {
                &.small-text {
                  font-size: 15px;
                }
              }

              @media only screen and (max-width: 830px) {
                font-size: 18px;
              }

              @media only screen and (max-width: 767px) {
                margin-top: 0;
              }

              @media only screen and (max-width: 725px) {
                &.small-text {
                  font-size: 13px;
                }
              }

              @media only screen and (max-width: 592px) {
                font-size: 16px;
                margin-left: 50px;
                width: 80%;
              }

              @media only screen and (max-width: 440px) {
                font-size: 14px;
                margin-top: 0;
                line-height: 1.5;
              }
            }

            &.no-image {
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 70%;

              @media only screen and (max-width: 991px) {
                width: 100%;
              }

              @media only screen and (max-width: 575px) {
                padding: 0 20px;
              }

              .slide-heading {
                font-size: 29px;

                @media only screen and (max-width: 991px) {
                  font-size: 26px;
                }

                @media only screen and (max-width: 767px) {
                  font-size: 20px;
                  margin-bottom: 12px;
                }
              }

              .slide-name {
                @media only screen and (max-width: 592px) {
                  margin-left: 0;
                }
              }

              .slide-title {
                @media only screen and (max-width: 592px) {
                  margin-left: 0;
                  width: 100%;
                }
              }
            }
          }

          &.no-image {
            .teal-circle {
              width: 145px;
              left: 0;
              top: 0;
              position: absolute;

              @media only screen and (max-width: 1200px) {
                width: 115px;
              }

              @media only screen and (max-width: 991px) {
                width: 95px;
                left: -125px;
              }
            }

            .purple-circle {
              width: 300px;
              right: -50px;
              bottom: 0;
              position: absolute;

              @media only screen and (max-width: 1200px) {
                width: 242px;
              }

              @media only screen and (max-width: 991px) {
                width: 224px;
                right: -130px;
              }
            }
          }
        }
      }
    }

    ol.carousel-indicators {
      li {
        height: 15px;
        width: 15px;
        border: 2px solid rgb(0, 142, 124);
        background-color: rgba(255, 255, 255, 0);
        border-radius: 50%;
        padding: 0;
        background-clip: unset;
        transition-duration: 0.3s;

        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }

        &.active {
          background-color: white;
          border: 2px solid white;
        }
      }

      @media only screen and (max-width: 592px) {
        bottom: -50px;

        li {
          &.active {
            background-color: rgb(0, 205, 179);
            border: 2px solid rgb(0, 205, 179);
          }
        }
      }
    }

    a.carousel-control-prev img,
    a.carousel-control-next img {
      width: 45px;
    }

    a.carousel-control-next,
    a.carousel-control-prev {
      @media only screen and (max-width: 1200px) {
        width: 10%;
      }

      @media only screen and (max-width: 830px) {
        display: none;
      }
    }

    a.carousel-control-prev.mobile,
    a.carousel-control-next.mobile {
      top: 348px;

      &.carousel-control-prev {
        left: 160px;
      }

      &.carousel-control-next {
        left: 350px;
      }
    }
  }

  .comp-section-two {
    position: relative;
    padding-top: 120px;

    .second-row {
      margin-bottom: 50px;

      .left-col {
        h2 {
          text-align: left;
        }

        p {
          text-align: left;
        }
      }

      .right-col {
        text-align: right;

        @media only screen and (max-width: 991px) {
          text-align: left;
        }
      }
    }
  }

  .section-two-dots {
    height: 380px;
    width: 542px;
    top: 120px;
    left: 18px;

    @media only screen and (max-width: 991px) {
      width: 235px;
      height: 235px;
      left: -28px;
      top: 91px;
    }
    @media only screen and (max-width: 575px) {
      top: 96px;
      left: 15px;
      height: 160px;
      width: 160px;
    }
  }
}

.video-window-layover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 23, 57, 0.8);
  backdrop-filter: blur(5px);
  z-index: 3;
  display: none;
  transition-duration: 0.5s;

  .video-open-wrapper {
    position: absolute;
    left: 50%;
    top: 51%;
    transform: translate(-50%, -50%);
    z-index: 4;
    color: white;
    width: 60%;

    @media only screen and (max-width: 930px) {
      width: 80%;
    }

    @media only screen and (max-width: 516px) {
      width: 90%;
    }

    h2 {
      color: white;
      text-align: left;

      @media only screen and (max-width: 516px) {
        font-size: 20px;
      }
    }

    .video-close-btn {
      position: absolute;
      top: 14px;
      right: 0;
      width: 34px;
      cursor: pointer;
    }

    iframe {
      width: 100%;
      aspect-ratio: 16/9;
      height: auto;
    }

    .video-social-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;

      p {
        display: inline;
        width: 95px;
        margin: 0;
        font-weight: bold;
        font-size: 20px;

        @media only screen and (max-width: 516px) {
          font-size: 18px;
        }
      }

      img {
        display: inline;
        width: 40px;
        height: 40px;
        margin-right: 15px;
        cursor: pointer;

        @media only screen and (max-width: 516px) {
          font-size: 30px;
        }
      }
    }
  }
}

@mixin font-size($sizeValue: 12 ){
    font-size: $sizeValue + px;
    font-size: (0.10 * $sizeValue) + rem;
}

@mixin line-height($heightValue: 12 ){
    line-height: $heightValue + px;
    line-height: (0.10 * $heightValue) + rem;
}

@mixin padding($top, $right, $bottom, $left) {
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bottom;
    padding-left: $left;
}

@mixin margin($top, $right, $bottom, $left) {
    margin-top: $top;
    margin-right: $right;
    margin-bottom: $bottom;
    margin-left: $left;
}

@mixin border-all($width, $style, $color) {
    border-width: $width !important;
    border-style: $style !important;
    border-color: $color !important;
}

@mixin border-radius($top-left-radius, $top-right-radius, $bottom-right-radius, $bottom-left-radius) {
    border-top-left-radius: $top-left-radius;
    border-top-right-radius: $top-right-radius;
    border-bottom-right-radius: $bottom-right-radius;
    border-bottom-left-radius: $bottom-left-radius;
}

@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin gradient($direction,$color1,$color2) {
    background: -webkit-linear-gradient($direction,$color1,$color2);
    background: -moz-linear-gradient($direction,$color1,$color2);
    background: -ms-linear-gradient($direction,$color1,$color2);
    background: -o-linear-gradient($direction,$color1,$color2);
    background: linear-gradient($direction,$color1,$color2);
}

@mixin transition($delay,$duration,$property,$timing) {
    -webkit-transition-property: $property;
    -webkit-transition-duration: $duration;
    -webkit-transition-timing-function: $timing;
    -webkit-transition-delay: $delay;
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $timing;
    transition-delay: $delay;
}

@mixin filter($filter) {
    -webkit-filter: $filter;
    -moz-filter: $filter;
    -ms-filter: $filter;
    -o-filter: $filter;
    filter: $filter;
}

@mixin selection-color($color,$selection) {
    ::-moz-selection {
        color: $color;
        background: $selection;
    }
    ::selection {
        color: $color;
        background: $selection;
    }
}
@charset "utf-8";
/* CSS Document */

* {
	margin: 0;
	padding: 0;
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-font-smoothing:antialiased;
	-moz-font-smoothing:antialiased;
	-o-font-smoothing:antialiased;
	font-smoothing:antialiased;
	text-rendering:optimizeLegibility;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
@import 'variables';
/* Page-wide styles */

.ty-container-fluid {
  padding: 0 !important;
  overflow: hidden !important;
}

.ty-container {
  padding: 0 !important;
}

.row.ty-split-row,
.row.ty-commitment-row,
.row.ty-full-row {
  margin-left: 0;
  margin-right: 0;
}

.hidden {
  display: none;
}

.ten-year-landing-wrapper {
  margin: 0;
  padding: 0 !important;
}

body.modal-open > .ten-year-landing-wrapper {
  filter: blur(10px);
}

/* End page-wide styles */
/* ===================================================================================== */
/* Hero section */

.ty-hero {
  height: 800px;
  width: 100%;
  background-size: 100% auto;
  background-image: url('/images/10-year-anniversary/landing-page/10-year-hero-bg.png');
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
}

.ty-hero-content {
  width: 60%;
}

.ty-hero-header {
  font-size: 65px;
  line-height: 1.3;
}

.ty-hero-subheader {
  font-size: 28px;
  line-height: 1.3;
}

.ty-map-image-wrapper {
  height: 800px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @media (max-width: 1350px) {
    height: 650px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.ty-map-image {
  object-fit: cover;
}

.ty-hero-video {
  position: absolute;
  width: 70vw;
  height: 40.25vw;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 700px;
  box-shadow: 0 40px 30px -20px grey;

  @media only screen and (max-width: 1600px) {
    top: 660px;
  }

  @media only screen and (max-width: 1500px) {
    top: 400px;
  }

  @media only screen and (max-width: 1350px) {
    width: 85vw;
    height: 48vw;
  }

  @media only screen and (max-width: 1200px) {
    top: 360px;
  }

  @media only screen and (max-width: 1000px) {
    top: 0;
    width: 100vw;
    height: 56.25vw;
    position: relative;
    padding: 40px;
  }

  @media only screen and (max-width: 800px) {
    padding: 0;
  }
}

.ty-subheader-mobile {
  padding: 30px;
  text-align: center;
}

.ty-subheader-mobile-wrapper {
  display: none;
}

/* End hero section */
/* ===================================================================================== */
/* Row sections */

.ty-full-row {
  position: relative;
  width: 100%;
  height: 430px;
  margin: 0;
}

.ty-full-row-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  z-index: 1;
}

.ty-full-row-title {
  flex: 50%;
  transition: 0.5s ease;
}

.ty-full-row-video {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ty-full-header {
  font-size: 65px;
  font-family: 'Invention Bold';
}

.ty-full-header.full-right {
  padding-left: 80px;
}

.ty-play-video-icon {
  height: 80px;
  width: auto;
  stroke: white;
  cursor: pointer;

  .cls-1 {
    fill: none;
  }

  .cls-2 {
    fill: white;
  }
}

.ty-split-row {
  height: auto;
  position: relative;
}

.ty-row-box h2 {
  font-size: 48px;
  display: inline;
  z-index: 200;
}

.ty-box-arrow {
  width: 48px;
  height: 48px;
  display: inline;
  margin-left: 20px;
  transition-duration: 0.5s;
  position: absolute;
}

.ty-row-box.split-left {
  padding-left: calc(((100vw - 1140px) / 2) + 35px);
}

/* End row sections */
/* ===================================================================================== */
/* Full row styles */

.ty-full-img-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.ty-full-img {
  object-fit: cover;
}

/* Full row layovers */

.ty-full-layover {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  left: -100%;
  transition: 0.5s ease;

  &#bravery-layover {
    background-color: rgb(13, 36, 63);
  }

  &#strength-layover {
    background-color: rgb(72, 73, 191);
  }

  &#resilience-layover {
    background-color: rgb(109, 206, 178);
  }
}

.full-slide-in {
  color: white;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.4;
  position: absolute;
  opacity: 0;
  top: 50%;
  transition: 0.5s ease;
  width: 50%;

  &.left-row {
    left: -150%;
  }

  &.right-row {
    left: 250%;
  }

  &#resilience-row {
    color: black;
  }
}

/* Full row hover states */

// .ty-full-row:hover {
//   .ty-full-layover {
//     left: 0;
//   }

//   .ty-play-video-icon {
//     stroke: rgb(193, 235, 70);

//     .cls-2 {
//       fill: rgb(193, 235, 70);
//     }
//   }

//   .ty-full-row-container {
//     .ty-full-row-title {
//       padding-bottom: 120px;
//       .ty-full-header {
//         color: rgb(207, 238, 47) !important;
//       }
//       .ty-full-header.resilience-text {
//         color: rgb(72, 73, 191) !important;
//       }
//     }

//     .full-slide-in {
//       left: 0;
//       opacity: 1;
//     }

//     .ty-play-video-icon {
//       stroke: rgb(207, 238, 47);
//       .cls-2 {
//         fill: rgb(207, 238, 47);
//       }
//     }

//     .ty-play-video-icon.resilience {
//       stroke: rgb(72, 73, 191);
//       .cls-2 {
//         fill: rgb(72, 73, 191);
//       }
//     }
//   }
// }

/* End full row styles */
/* ===================================================================================== */
/* Box background images/colors/layovers */

.ty-row-box {
  // z-index: 1;
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 0 0 80px 80px;
  transition: 0.5s ease;
  height: 380px;
}

.ty-box-wrapper {
  padding-top: 300px;
  transition: 0.5s ease;
  width: 80%;
  z-index: 4;
  position: relative;
}

.ty-box-layover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0.7;
}

.ty-box-img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: left ease 0.5s, background 0.5s ease;
  display: flex;
  align-content: stretch;
  justify-content: stretch;
  z-index: 1;
}

.ty-box-img {
  filter: grayscale(1);
  object-fit: cover;
  transition: filter 0.5s ease;
}

.creativity-img-credit {
  position: absolute;
  color: white;
  font-weight: bold;
  right: 10px;
  text-align: right;
  bottom: -10px;
  font-size: 10px;
}

.ty-box-text {
  transition: 0.5s ease;
  font-family: 'Invention Bold';
}

/* Slide out styles */

.slide-out-wrapper {
  position: absolute;
  top: 30%;
  transition: 0.5s ease;
  opacity: 0;
  width: 700px;
  height: 0;
  padding-top: 30px;
  padding-left: 80px;

  &.left-box {
    left: -100%;
  }

  &.right-box {
    left: 100%;
  }
}

.slide-out-text {
  padding-top: 30px;
  font-size: 26px;
  line-height: 1.2;
  color: black;
  width: 80%;
  font-weight: bold;

  &.connection-text {
    color: white;
  }
}

.slide-out-link-wrapper {
  cursor: pointer;

  .slide-out-link {
    display: inline;
    color: rgb(18, 133, 124) !important;
    font-weight: bold;
    font-size: 28px;

    &.patience-text {
      color: rgb(192, 235, 69) !important;
    }

    &.connection-text {
      color: rgb(105, 183, 246) !important;
    }

    &.creativity-text {
      color: rgb(207, 238, 47) !important;
    }

    &.imagination-text {
      color: rgb(72, 73, 191) !important;
    }

    &.commitment-text {
      color: rgb(207, 238, 47) !important;
    }
  }

  .slide-out-arrow {
    height: 28px;
    width: 28px;
    // filter: brightness(0%);
    display: inline;
    margin-left: 15px;
    margin-top: -10px;
  }
}

.img-triangle-wrapper {
  z-index: 999;
  position: absolute;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;

  &.left-box {
    left: 0;
    transition: 0.5s ease;
  }

  &.right-box {
    left: 90%;
    transition: 0.5s ease;
  }
}

.img-triangle {
  height: 60px;
  background-color: transparent;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;

  &.left-box {
    border-right: 50px solid transparent;
  }

  &.right-box {
    border-left: 50px solid transparent;
  }

  &#empathy-triangle {
    border-left: 50px solid rgb(207, 238, 47);
  }

  &#patience-triangle {
    border-right: 50px solid rgb(18, 133, 124);
  }

  &#connection-triangle {
    border-left: 50px solid rgb(13, 36, 63);
  }

  &#creativity-triangle {
    border-right: 50px solid rgb(105, 183, 246);
  }

  &#imagination-triangle {
    border-left: 50px solid rgb(191, 237, 51);
  }

  &#commitment-triangle {
    border-right: 50px solid rgb(105, 140, 232);
  }
}

/* Individual box styles */

#empathy-box,
#empathy-layover {
  // background-color: rgb(124, 128, 104);
  background-color: rgb(207, 238, 47);
}

#patience-box,
#patience-layover {
  background-color: rgb(19, 133, 124);
}

#connection-box,
#connection-layover {
  background-color: rgb(13, 36, 63);
}

#creativity-box,
#creativity-layover {
  background-color: rgb(105, 183, 246);
}

#imagination-box,
#imagination-layover {
  background-color: rgb(191, 237, 51);
}

#commitment-box {
  background-color: rgb(105, 140, 232);
}

#commitment-box {
  background-color: rgb(105, 140, 232);

  .ty-box-img-wrapper {
    background-color: rgb(105, 140, 232);

    .commitment-box-timeline {
      position: absolute;
      transition: ease 0.5s;
      top: 40%;

      #Layer_2 {
        #Layer_1-2 {
          * {
            transition: ease 0.5s;
          }
        }
      }
    }
  }
}

/* Individual row styles */

.ty-strength-row {
  background-image: url('/images/10-year-anniversary/landing-page/10-year-strength-bg.jpg');
}

.ty-resilience-row {
  background-image: url('/images/10-year-anniversary/landing-page/10-year-resilience-bg.jpg');
}

/* End box background images/colors/layovers */
/* ===================================================================================== */
/* Box hover states */

// .ty-row-box:hover {
//   .ty-box-wrapper {
//     padding-top: 100px;
//     z-index: 999;

//     .ty-box-arrow {
//       opacity: 0;
//     }
//   }

//   .ty-box-layover {
//     z-index: 4;
//   }

//   .ty-box-img-wrapper {
//     z-index: 3;
//   }

//   .slide-out-wrapper {
//     opacity: 1;
//     left: 0;
//     z-index: 999;
//     height: auto;
//     -webkit-transition: 0.5s linear;
//     -moz-transition: 0.5s linear;
//     -ms-transition: 0.5s linear;
//     -o-transition: 0.5s linear;
//     transition: 0.5s linear;
//   }

//   .ty-box-img-wrapper {
//     left: 100%;
//     z-index: 5;

//     .ty-box-img {
//       filter: grayscale(0%);
//     }
//   }

//   .img-triangle-wrapper {
//     opacity: 1;
//     &.left-box {
//       left: 100%;
//     }
//     &.right-box {
//       left: calc(-100px);
//     }
//   }

//   &.right-box:hover {
//     .ty-box-img-wrapper {
//       left: -100%;
//     }
//   }

//   &#empathy-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         // color: rgb(88, 174, 102) !important;
//         color: rgb(18, 133, 124) !important;
//       }
//     }
//   }

//   &#patience-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         // color: rgb(207, 238, 48) !important;
//         color: rgb(192, 235, 69) !important;
//       }
//     }
//   }

//   &#connection-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         color: rgb(105, 183, 246) !important;
//       }
//     }
//   }

//   &#creativity-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         color: rgb(207, 238, 47) !important;
//       }
//     }
//   }

//   &#imagination-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         color: rgb(72, 73, 191) !important;
//       }
//     }
//   }

//   &#commitment-box {
//     .ty-box-wrapper {
//       .ty-box-text {
//         color: rgb(207, 238, 47) !important;
//       }
//     }
//     .ty-box-img-wrapper {
//       background-color: rgb(12, 36, 64);

//       .commitment-box-timeline {
//         width: 95%;
//         top: 44%;

//         #Layer_2 {
//           #Layer_1-2 {
//             .cls-1 {
//               fill: $limeColor !important;
//             }

//             .cls-12 {
//               fill: $tealColor !important;
//             }

//             .cls-3 {
//               fill: white;
//             }

//             .cls-13 {
//               fill: black;
//             }
//           }
//         }
//       }
//     }
//   }
// }

/* End box hover states */

.ty-commitment-header {
  margin: 0px auto;
}

.ty-commitment-header-text {
  display: inline;
  font-size: 60px;
  font-family: 'Invention Bold';
}

.ty-large-arrow {
  display: inline;
  height: 60px;
  width: 60px;
}

.ty-commitment-content {
  display: block;
  column-count: 2;
  margin-top: 180px;
  font-size: 23px;
  line-height: 1.3;

  @media only screen and (max-width: 1100px) {
    column-count: 1;
  }
}

@media (max-width: 1930px) {
  .ty-map-image-wrapper {
    height: 750px;
  }
}

@media (max-width: 1600px) {
  .ty-hero {
    height: 100%;
    width: auto;
    padding: 80px 0;
  }

  .ty-map-image-wrapper {
    height: 650px;
  }
}

@media (max-width: 1500px) {
  .ty-hero-header {
    font-size: 48px;
    line-height: auto;
  }

  .ty-hero-subheader {
    font-size: 18px;
    line-height: auto;
  }
}

@media (max-width: 1440px) {
  .ty-box-wrapper {
    padding-top: 250px;
  }
}

@media (max-width: 1280px) {
  // .row.ty-split-row {
  //   height: 350px;
  // }

  .ty-box-wrapper {
    padding-top: 250px;
  }
}

@media (max-width: 1200px) {
  .ty-hero-header {
    font-size: 35px;
  }

  .ty-map-image-wrapper {
    height: 600px;
  }

  .ty-hero {
    padding: 40px 0;
  }

  .ty-hero-content-container {
    width: 95%;
  }

  // .row.ty-split-row,
  // .row.ty-full-row {
  //   height: 300px;
  // }

  .slide-out-text {
    width: 60%;
  }
}

@media (max-width: 1100px) {
  .ty-map-image-wrapper {
    height: 580px;
  }
}

@media (max-width: 1024px) {
  .ty-hero-content {
    width: 60%;
  }

  .ty-hero {
    padding: 20px 0 20px;
  }

  .ty-hero-header {
    font-size: 40px;
  }
}

@media (max-width: 992px) {
  .slide-out-text {
    width: 80%;
  }
  .ty-map-image-wrapper {
    height: 500px;
  }
}

@media (max-width: 850px) {
  .ty-hero-subheader {
    display: none;
  }

  .ty-subheader-mobile-wrapper {
    display: block;
  }

  .ty-hero-header {
    padding: 80px 0;
  }
}

@media (min-width: 768px) {
  /* Full row hover states */

  .ty-full-row:hover {
    .ty-full-layover {
      left: 0;
    }

    .ty-play-video-icon {
      stroke: rgb(193, 235, 70);

      .cls-2 {
        fill: rgb(193, 235, 70);
      }
    }

    .ty-full-row-container {
      .ty-full-row-title {
        padding-bottom: 120px;
        .ty-full-header {
          color: rgb(207, 238, 47) !important;
        }
        .ty-full-header.resilience-text {
          color: rgb(72, 73, 191) !important;
        }
      }

      .full-slide-in {
        left: 0;
        opacity: 1;
      }

      .ty-play-video-icon {
        stroke: rgb(207, 238, 47);
        .cls-2 {
          fill: rgb(207, 238, 47);
        }
      }

      .ty-play-video-icon.resilience {
        stroke: rgb(72, 73, 191);
        .cls-2 {
          fill: rgb(72, 73, 191);
        }
      }
    }
  }

  /* Box hover states */

  .ty-row-box:hover {
    .ty-box-wrapper {
      padding-top: 100px;
      z-index: 999;

      .ty-box-arrow {
        opacity: 0;
      }
    }

    .ty-box-layover {
      z-index: 4;
    }

    .ty-box-img-wrapper {
      z-index: 3;
    }

    .slide-out-wrapper {
      opacity: 1;
      left: 0;
      z-index: 999;
      height: auto;
      -webkit-transition: 0.5s linear;
      -moz-transition: 0.5s linear;
      -ms-transition: 0.5s linear;
      -o-transition: 0.5s linear;
      transition: 0.5s linear;
    }

    .ty-box-img-wrapper {
      left: 100%;
      z-index: 5;

      .ty-box-img {
        filter: grayscale(0%);
      }
    }

    .img-triangle-wrapper {
      opacity: 1;
      &.left-box {
        left: 100%;
      }
      &.right-box {
        left: calc(-100px);
      }
    }

    &.right-box:hover {
      .ty-box-img-wrapper {
        left: -100%;
      }
    }

    &#empathy-box {
      .ty-box-wrapper {
        .ty-box-text {
          // color: rgb(88, 174, 102) !important;
          color: rgb(18, 133, 124) !important;
        }
      }
    }

    &#patience-box {
      .ty-box-wrapper {
        .ty-box-text {
          // color: rgb(207, 238, 48) !important;
          color: rgb(192, 235, 69) !important;
        }
      }
    }

    &#connection-box {
      .ty-box-wrapper {
        .ty-box-text {
          color: rgb(105, 183, 246) !important;
        }
      }
    }

    &#creativity-box {
      .ty-box-wrapper {
        .ty-box-text {
          color: rgb(207, 238, 47) !important;
        }
      }
    }

    &#imagination-box {
      .ty-box-wrapper {
        .ty-box-text {
          color: rgb(72, 73, 191) !important;
        }
      }
    }

    &#commitment-box {
      .ty-box-wrapper {
        .ty-box-text {
          color: rgb(207, 238, 47) !important;
        }
      }
      .ty-box-img-wrapper {
        background-color: rgb(12, 36, 64);

        .commitment-box-timeline {
          width: 95%;
          top: 44%;

          #Layer_2 {
            #Layer_1-2 {
              .cls-1 {
                fill: $limeColor !important;
              }

              .cls-12 {
                fill: $tealColor !important;
              }

              .cls-3 {
                fill: white;
              }

              .cls-13 {
                fill: black;
              }
            }
          }
        }
      }
    }
  }

  /* End box hover states */
}

@media (max-width: 768px) {
  .ty-box-img {
    filter: initial;
  }

  .ty-full-row-container {
    .full-slide-in {
      left: 0;
      opacity: 1;
    }

    .ty-full-row-title {
      padding-bottom: 120px;
      .ty-full-header {
        color: rgb(207, 238, 47) !important;
      }
      .ty-full-header.resilience-text {
        color: rgb(72, 73, 191) !important;
      }
    }

    .ty-play-video-icon {
      stroke: rgb(207, 238, 47);
      .cls-2 {
        fill: rgb(207, 238, 47);
      }
    }

    .ty-play-video-icon.resilience {
      stroke: rgb(72, 73, 191);
      .cls-2 {
        fill: rgb(72, 73, 191);
      }
    }
  }

  .ty-box-wrapper {
    padding-top: 100px;
  }

  #empathy-box {
    .ty-box-text {
      color: rgb(18, 133, 124) !important;
    }
  }

  #patience-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(192, 235, 69) !important;
      }
    }
  }

  #connection-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(105, 183, 246) !important;
      }
    }
  }

  #creativity-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(207, 238, 47) !important;
      }
    }
  }

  #imagination-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(72, 73, 191) !important;
      }
    }
  }

  .slide-out-wrapper.left-box,
  .slide-out-wrapper.right-box {
    opacity: 1;
    left: 0;
    z-index: 999;
  }

  .ty-box-arrow {
    display: none;
  }

  #commitment-box {
    .ty-box-wrapper {
      .ty-box-text {
        color: rgb(207, 238, 47) !important;
      }
    }
    .ty-box-img-wrapper {
      background-color: rgb(12, 36, 64);

      .commitment-box-timeline {
        width: 95%;
        top: 44%;

        #Layer_2 {
          #Layer_1-2 {
            .cls-1 {
              fill: $limeColor !important;
            }

            .cls-12 {
              fill: $tealColor !important;
            }

            .cls-3 {
              fill: white;
            }

            .cls-13 {
              fill: black;
            }
          }
        }
      }
    }

    .ty-box-wrapper {
      position: absolute;
      top: 90%;
      width: 100%;
    }

    .slide-out-wrapper.right-box {
      position: absolute;
      top: 120%;
      width: 100%;
    }
  }

  .box-commitment {
    height: 350px;
    background-color: #698ce8;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .ty-hero-header {
    font-size: 32px;
    padding: 10px 0;
  }

  .ty-map-image-wrapper {
    height: 400px;
  }

  .ty-full-row-container .full-slide-in {
    left: 35px;
    font-size: 24px;
  }

  .ty-full-row-container .ty-full-row-title {
    padding-left: 25px;
  }

  .slide-out-text {
    font-size: 22px;
    text-align: left !important;
  }

  // .ty-row-box {
  //   padding-left: 30px;
  // }

  .ty-box-wrapper {
    padding-top: 60px;
  }

  .ty-full-header {
    font-size: 48px;
  }

  .ty-play-video-icon {
    height: 55px;
  }

  .slide-out-wrapper {
    width: 100%;
    // padding-left: 30px;
    top: 20%;
  }

  #commitment-box .ty-box-wrapper {
    padding-top: 105px;
  }

  #bravery .ty-full-row-title {
    padding-left: 0px;
  }

  .ty-row-box {
    padding: 0 0 30px 30px;
  }

  .slide-out-wrapper.left-box,
  .slide-out-wrapper.right-box {
    left: -10%;
  }
}

@media (max-width: 448px) {
  .ty-hero-header {
    font-size: 28px;
  }

  .ty-play-video-icon {
    height: 45px;
  }

  .ty-row-box {
    padding: 0 0 30px 30px;
  }

  .slide-out-wrapper.left-box,
  .slide-out-wrapper.right-box {
    left: -13%;
  }

  .ty-full-row-container .ty-full-row-title {
    padding-left: 33px;
  }

  #bravery .ty-full-row-title {
    padding-left: 20px;
  }

  .ty-full-row-container .full-slide-in {
    left: 35px;
    font-size: 18px;
  }

  .ty-full-header,
  .ty-row-box h2 {
    font-size: 40px;
  }

  .slide-out-link-wrapper .slide-out-link {
    font-size: 22px;
  }

  .slide-out-text {
    font-size: 20px;
  }

  .slide-out-text {
    width: 90%;
  }
}

/* End media queries */
/* ===================================================================================== */
/* Modals */

.share-icon:hover {
  cursor: pointer;
}

.modal-dialog {
  max-width: 100% !important;
  width: 75% !important;

  @media only screen and (max-width: 650px) {
    width: 100% !important;
  }
}

.modal-content {
  border-radius: 0 !important;
}

.modal-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
  background-color: transparent;
  font-size: 130px;
  color: $tealColor;

  &.commitment-modal {
    color: $limeColor;
  }

  @media only screen and (max-width: 675px) {
    right: 10px;
    top: 10px;
    font-size: 80px;
  }
}

.modal-container {
  width: 80%;
  margin: auto;

  @media only screen and (max-width: 1680px) {
    &.wide {
      width: 85%;
    }
  }

  @media only screen and (max-width: 1600px) {
    &.wide {
      width: 90%;
    }
  }

  @media only screen and (max-width: 1500px) {
    &.wide {
      width: 95%;
    }
  }

  @media only screen and (max-width: 1000px) {
    width: 95%;
  }
}

.modal-wrapper {
  * {
    border: none;
  }
}

.modal-hero-wrapper {
  background-image: url('/images/10-year-anniversary/landing-page/10-year-patience-modal-header.png');
  width: 100%;
  padding-top: 40px;
  padding-bottom: 300px;
  position: relative;

  &#commitment-modal {
    background-image: url('/images/10-year-anniversary/landing-page/10-year-commitment-hero-wrapper.png');
  }

  @media only screen and (max-width: 1200px) {
    padding-bottom: 200px;
  }

  @media only screen and (max-width: 446px) {
    padding-bottom: 100px;
  }
}

.modal-header {
  color: rgb(193, 237, 50);
  font-size: 55px;
  font-weight: bold;
  padding-left: 16px;

  @media only screen and (max-width: 675px) {
    font-size: 40px;
  }
}

.modal-subheader {
  color: white;
  font-size: 40px;
  font-weight: normal;
  line-height: 1.3;

  @media only screen and (max-width: 675px) {
    font-size: 35px;
  }
}

.modal-hero-stripe {
  width: 100%;
  height: 60px;
  background-color: rgb(216, 216, 216);
}

.modal-hero-img {
  width: 90%;
  height: auto;
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  top: 300px;

  &.video-modal {
    top: 230px;
    width: 60vw;
    height: 33.75vw;
  }

  &.modal-carousel {
    width: 100%;
    top: 280px;
    height: 550px;
  }

  &.low-video {
    top: 270px;
  }

  @media only screen and (max-width: 1450px) {
    &.modal-carousel {
      height: 450px;
    }
  }

  @media only screen and (max-width: 1200px) {
    &.modal-carousel {
      height: 400px;
    }

    &.commitment-modal {
      top: 250px;
    }
  }

  @media only screen and (max-width: 1000px) {
    &.video-modal {
      top: 280px;
      width: 70vw;
      height: 39.375vw;
    }

    &.modal-carousel {
      height: 330px;
    }
  }

  @media only screen and (max-width: 900px) {
    top: 330px;

    &.commitment-modal {
      top: 260px;
    }
  }

  @media only screen and (max-width: 879px) {
    &.modal-carousel {
      top: 320px;
      height: 260px;
    }
  }

  @media only screen and (max-width: 760px) {
    &.video-modal {
      position: relative;
      top: 0;
      margin-top: 0px;
      width: 100%;
      height: 39.75vw;
    }

    &.connection-modal {
      top: 260px;
    }
  }

  @media only screen and (max-width: 650px) {
    &.patience-modal {
      top: 265px;
    }

    &.video-modal {
      height: 56.25vw;
    }
  }

  @media only screen and (max-width: 640px) {
    top: 210px;

    &.modal-carousel {
      top: 220px;
    }
  }

  @media only screen and (max-width: 600px) {
    top: 220px;

    &.modal-carousel {
      top: 260px;
    }
  }

  @media only screen and (max-width: 563px) {
    &.creativity-modal {
      top: 270px;
    }
  }

  @media only screen and (max-width: 541px) {
    &.modal-carousel {
      height: 220px;
    }
  }

  @media only screen and (max-width: 522px) {
    top: 220px;
    width: 100%;
  }

  @media only screen and (max-width: 494px) {
    top: 260px;

    &.creativity-modal {
      top: 220px;
    }

    &.connection-modal {
      top: 240px;
    }

    &.commitment-modal {
      top: 220px;
    }
  }

  @media only screen and (max-width: 373px) {
    &.commitment-modal {
      top: 260px;
    }
  }

  @media only screen and (max-width: 358px) {
    top: 310px;

    &.connection-modal {
      top: 280px;
    }
  }

  @media only screen and (max-width: 320px) {
    &.patience-modal {
      top: 360px;
    }
  }
}

.modal-body-section {
  margin-top: 35%;

  &.video-modal {
    margin-top: 32%;
  }

  &.imagination-modal {
    margin-top: 45%;
  }

  &.resilience-modal {
    margin-top: 38%;
  }

  @media only screen and (max-width: 1600px) {
    &.video-modal {
      margin-top: 25%;
    }
  }

  @media only screen and (max-width: 1625px) {
    margin-top: 28%;

    &.imagination-modal {
      margin-top: 50%;
    }

    &.commitment-modal {
      margin-top: 33%;
    }
  }

  @media only screen and (max-width: 1500px) {
    margin-top: 33%;

    &.commitment-modal {
      margin-top: 28%;
    }
  }

  @media only screen and (max-width: 1450px) {
    &.imagination-modal {
      margin-top: 40%;
    }
  }

  @media only screen and (max-width: 1400px) {
    &.video-modal {
      margin-top: 18%;
    }
  }

  @media only screen and (max-width: 1316px) {
    margin-top: 25%;

    &.low-modal {
      margin-top: 10%;
    }
  }

  @media only screen and (max-width: 1200px) {
    &,
    &.creativity-modal,
    &.connection-modal {
      margin-top: 33%;
    }

    &.imagination-modal {
      margin-top: 30%;
    }

    &.video-modal {
      margin-top: 25%;
    }

    &.commitment-modal {
      margin-top: 33%;
    }
  }

  @media only screen and (max-width: 1156px) {
    margin-top: 30%;
  }

  @media only screen and (max-width: 1000px) {
    &.imagination-modal {
      margin-top: 30%;
    }

    &.connection-modal {
      margin-top: 30%;
    }

    &.video-modal {
      margin-top: 25%;
    }
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 20%;
  }

  @media only screen and (max-width: 1070px) {
    &.bravery-modal {
      margin-top: 15%;
    }
  }

  @media only screen and (max-width: 1000px) {
    &.imagination-modal {
      margin-top: 20%;
    }
  }

  @media only screen and (max-width: 920px) {
    &.commitment-modal {
      margin-top: 28%;
    }
  }

  @media only screen and (max-width: 818px) {
    &.video-modal {
      margin-top: 10%;
    }
  }

  @media only screen and (max-width: 766px) {
    &.connection-modal {
      margin-top: 23%;
    }
  }

  @media only screen and (max-width: 760px) {
    &.imagination-modal {
      margin-top: 32%;
    }

    &.creativity-modal {
      margin-top: 25%;
    }

    &.video-modal {
      margin-top: 0 !important;
    }
  }

  @media only screen and (max-width: 640px) {
    margin-top: 10%;

    &.commitment-modal {
      margin-top: 22%;
    }
  }

  @media only screen and (max-width: 446px) {
    &,
    &.connection-modal {
      padding-bottom: 100px;
      margin-top: 30%;
    }

    &.commitment-modal {
      margin-top: 51%;
    }

    &.commitment-modal {
      margin-top: 40%;
    }
  }
}

@media (max-width: 1500px) {
  .modal-stats-section {
    padding-bottom: 200px;
  }
}

.modal-text-wrapper {
  margin-top: 50px;
}

.modal-text {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
}

.divider {
  width: 30%;
  height: 4px;

  &.teal-divider {
    background: $tealColor;
  }

  &.white-divider {
    background: white;
  }

  @media only screen and (max-width: 760px) {
    display: none;
  }
}

.modal-share-header {
  font-weight: bold;
  font-family: 'Invention Bold';
}

.share-icon-wrapper {
  display: flex;
  justify-content: space-around;
}

.share-icon {
  height: 50px;
  width: 50px;
  display: inline;
}

.modal-stats-section {
  background-color: rgb(216, 216, 216);
  padding-top: 70px;
  margin-top: 70px;
  padding-bottom: 200px;

  @media only screen and (max-width: 1500px) {
    padding-bottom: 200px;
  }
}

.stats-row {
  margin-top: 70px;
  padding-bottom: 30px;
  border-bottom: 2px dotted rgb(58, 131, 124);
}

.stat-column-wrapper {
  padding: 0px 50px 10px 50px;
}

.stat-column-number {
  position: relative;
}

.stat-column-wrapper + .stat-column-wrapper {
  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 1px;
    height: 80%;
    border-right: 2px dotted rgb(58, 131, 124);
    z-index: 10;
  }
}

.stat-number-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 100px;
  font-weight: bold;
  color: rgb(27, 39, 65);
  font-family: 'Invention Black';

  &.large-stat-wrapper {
    .stat-column-number > span {
      font-size: 200px;
      @media screen and (max-width: 1800px) {
        font-size: 165px;
      }
      @media screen and (max-width: $break-xlarge) {
        font-size: 125px;
      }
      @media screen and (max-width: 1400px) {
        font-size: 100px;
      }
    }

    .stat-number-bg {
      width: 100%;
      top: 40%;
    }
  }
}

.stat-column-number {
  padding-bottom: 10px;
  z-index: 10;

  @media screen and (max-width: $break-xlarge) {
    padding-bottom: 20px;
  }
}

.stat-number-bg {
  position: absolute;
  background-color: rgb(144, 204, 177);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  z-index: 0;
}

.stat-number-label-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-weight: bold;
  color: rgb(27, 39, 65);
  font-family: 'Invention Black';
  align-items: center;
  font-size: 70px;

  .stat-column-number {
    font-size: 70px;
    z-index: 10;
    @media screen and (max-width: $break-xlarge) {
      font-size: 65px;
    }

    @media screen and (max-width: $break-large) {
      font-size: 50px;
    }
  }

  .stat-column-label {
    font-size: 20px;
    // margin-left: 70px;
    text-align: center;
    // margin-top: -15px;
    z-index: 1;
    text-align: left;
    position: absolute;
    top: 65px;
    left: 160px;
    width: 200px;
    @media screen and (max-width: $break-medium) {
      font-size: 16px;
      top: 52px;
      width: 185px;
    }
  }

  .stat-column-label.nine-thou-lives {
    left: 135px;
  }

  .stat-column-label.label1 {
    top: 80px;
    left: 180px;
    @media screen and (max-width: $break-medium) {
      top: 75px;
      left: 165px;
    }
  }

  .stat-column-label.label2 {
    left: 180px;
    @media screen and (max-width: $break-medium) {
      left: 165px;
    }
  }

  .stat-column-label.label3 {
    left: 262px;
    @media screen and (max-width: $break-medium) {
      left: 235px;
    }
  }

  .stat-number-bg {
    top: 26px;
    height: 50px;
  }
}

.stat-column-text {
  font-weight: bold;
  padding-top: 28px;
  line-height: 1.5;
  font-size: 20px;
}
.stat-text-large {
  color: rgb(58, 131, 124);
  font-weight: bold;
  font-size: 28px;
  line-height: 0.5;
}

.large-teal-text {
  color: $tealColor;
  font-size: 26px;
}

.supplies-count {
  padding: 0 50px;
  @media screen and (max-width: $break-large) {
    padding: 0 20px;
  }
  @media screen and (max-width: $break-medium) {
    padding: 0 50px;
  }
}

/* Timeline */

.modal-timeline-wrapper {
  margin-top: 100px;
  display: flex;
  flex-direction: column;

  .timeline-item-row {
    .timeline-item-wrapper {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .timeline-item-text {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;

        &::after {
          content: ' ';
          width: 1px;
          border-right: $darkBlueColor 3px dotted;
          display: block;
          margin: 10px auto 0 auto;
          z-index: 1;
        }

        &.timeline-one::after {
          height: 100px;
        }

        &.timeline-two::after {
          height: 40px;
        }

        &.timeline-three::after {
          height: 60px;
        }

        &.timeline-four::after {
          height: 40px;
        }

        &.timeline-five::after {
          height: 60px;
        }
      }

      .timeline-circle {
        position: absolute;
        height: 15px;
        width: 15px;
        border-radius: 50px;
        border: solid $tealColor 3px;
        background-color: rgb(216, 216, 216);
        z-index: 10;
        bottom: -9px;
      }

      @media only screen and (max-width: 1175px) {
        .timeline-item-text {
          font-size: 16px;
        }
      }

      @media only screen and (max-width: 991px) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 150px;

        & + .timeline-item-wrapper {
          border-top: dotted $darkBlueColor 2px;
        }

        .timeline-item-text {
          text-align: left;
          font-size: 20px;
        }

        .timeline-item-text::after {
          display: none;
        }

        .timeline-circle {
          display: none;
        }
      }

      @media only screen and (max-width: 470px) {
        .timeline-item-text {
          font-size: 18px;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      display: block !important;
      margin: 0 15px;
    }
  }

  .timeline-date-row {
    border-top: 3px dotted $tealColor;
    padding-top: 30px;
    z-index: 1;

    .timeline-date-text {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (max-width: 991px) {
    flex-direction: row-reverse;

    .timeline-date-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: none;
      border-right: 3px dotted $tealColor;
      padding-top: 0;

      .timeline-date-text {
        height: 150px;

        @media only screen and (max-width: 470px) {
          font-size: 20px;
        }
      }
    }
  }
}
/* End Timeline */

@media (max-width: 992px) {
  .divider {
    width: 50%;
  }

  .stat-column-wrapper + .stat-column-wrapper {
    &::before {
      display: none;
    }
  }

  .stat-column-text {
    text-align: center;
  }

  .modal-share-header {
    text-align: center;
    font-size: 22px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .modal-stats-section {
    padding-bottom: 100px;
  }
}

@media (max-width: 755px) {
  .modal-subheader {
    font-size: 35px;
  }
}

@media (max-width: 640px) {
  .modal-subheader {
    text-align: left !important;
    font-size: 27px;
  }
}

/* Creativity Modal */
.life-bank-wrapper::after {
  content: ' ';
  height: 80%;
  position: absolute;
  top: 0;
  width: 1px;
  border-right: 2px dotted $tealColor;
  right: 0;

  @media screen and (max-width: $break-medium) {
    border-right: none;
  }
}

.penda-health-wrapper {
  padding-left: 50px;

  @media only screen and (max-width: 768px) {
    padding: 35px 0;
  }

  .row {
    .col-lg-4 {
      .stat-number-wrapper {
        .stat-number-bg {
          width: 150px;
        }
      }
    }
  }
}

.life-bank-wrapper {
  padding-right: 30px;

  .life-bank-title {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 768px) {
    border-bottom: 2px $tealColor dotted;
    padding-bottom: 40px;

    &::after {
      display: none;
    }

    .life-bank-title {
      font-size: 20px;
      margin-bottom: 12px;
      text-align: center;
    }

    .life-bank-caption {
      font-size: 20px;
      padding: 10px 0;
      text-align: center;
    }
  }
}

.penda-health-para {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.5;
  padding-top: 40px;
  margin-bottom: 0;

  @media screen and (max-width: $break-large) {
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
}

.unjani-wrapper {
  @media screen and (max-width: $break-medium) {
    margin-top: 40px !important;
  }
  .unjani-stat-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  .top-para,
  .bottom-para {
    font-size: 20px;
    @media screen and (max-width: $break-xlarge) {
      font-size: 18px;
    }
    @media screen and (max-width: $break-large) {
      font-size: 16px;
    }
    @media screen and (max-width: $break-medium) {
      text-align: left;
    }
  }

  .stat-number-wrapper.large-stat-wrapper.with-label {
    display: flex;
    flex-direction: column;
    text-align: center;

    .stat-column-number {
      padding-bottom: 0;
    }

    p {
      z-index: 1;
      text-align: center;
    }

    .stat-column-label {
      text-align: center;
      font-size: 30px;
      // margin-top: -10px;
      @media screen and (max-width: $break-large) {
        font-size: 22px;
      }
    }

    .stat-number-bg {
      bottom: 10px;
    }
  }

  .stat-bottom-label {
    font-size: 22px;
    padding-right: 110px;
    padding-top: 10px;
    @media screen and (max-width: $break-xlarge) {
      padding-right: 55px;
    }

    @media screen and (max-width: $break-large) {
      font-size: 18px;
    }
  }

  .bottom-para {
    margin-top: 20px;
    line-height: 1.6;
    font-size: 20px;
  }

  @media screen and (max-width: 1200px) {
    padding-top: 35px;
    .top-para {
      text-align: center;
      font-size: 20px;
    }

    .unjani-stat-wrapper {
      align-items: center;
    }

    .stat-bottom-label {
      padding: 0;
    }

    .bottom-para {
      text-align: center;
    }
  }
}

.unjani-right-wrapper {
  display: flex;
  flex-direction: column;

  .unjani-right-row {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 991px) {
      justify-content: flex-start;
    }

    &.mid-row {
      margin-right: 150px;
    }
  }

  .unjani-right-stat-wrapper {
    display: flex;
    flex-direction: column;

    .stat-number-label-wrapper {
      display: block;
      flex-direction: row;

      @media only screen and (max-width: 556px) {
        .stat-column-number {
          font-size: 70px;
        }

        .stat-number-bg {
          height: 50px;
        }

        .stat-column-number {
          .stat-column-label {
            font-size: 17px;
          }
        }

        &.top-row {
          .stat-number-bg {
            width: 145px;
          }

          .stat-column-number {
            .stat-column-label {
              left: 120px;
              top: 50px;
            }
          }
        }
        &.mid-row {
          .stat-number-bg {
            width: 145px;
          }

          .stat-column-number {
            .stat-column-label {
              left: 125px;
              top: 30px;
            }
          }
        }
        &.bottom-row {
          .stat-number-bg {
            width: 200px;
          }

          .stat-column-number {
            .stat-column-label {
              left: 2180px;
              top: 30px;
            }
          }
        }
      }
    }

    p {
      font-weight: bold;

      @media only screen and (max-width: 1200px) {
        font-size: 20px;
        margin-top: 20px;
      }
    }

    .stat-number-bg {
      height: 60px;
      top: 40%;
      // width: 78%;
      width: 200px;
      margin-left: -10px;
      z-index: 1;
    }

    .stat-number-bg.stat-bg3 {
      width: 285px;
      @media screen and (max-width: 991px) {
        width: 255px;
      }
    }

    .stat-column-number {
      display: inline;
      font-size: 100px;
      @media screen and (max-width: 991px) {
        font-size: 85px;
      }
    }

    // .stat-column-label {
    //   display: inline;
    //   margin-left: -15px;
    //   text-align: left;
    //   margin-top: 0;
    // }
  }
}

.row.stats-row.jacaranda-row {
  @media only screen and (max-width: 640px) {
    padding-top: 35px;
  }
}

.stat-number-wrapper.jacaranda-row {
  .stat-number-bg {
    width: 110%;

    @media screen and (max-width: $break-large) {
      height: 70px;
      top: 24%;
    }
    @media only screen and (max-width: 1400px) {
      top: 25%;
      height: 80px;
    }
  }
}

.jacaranda-para {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5;
  padding-left: 30px;

  @media only screen and (max-width: 1200px) {
    font-size: 20px;
  }
}

.trail1 {
  position: absolute;
  top: 67%;
  height: 350px;
  left: 35%;
  z-index: 0;
}

.trail2 {
  height: 150px;
  position: absolute;
  left: 80%;
  top: 67%;
  z-index: 0;
}

.trail3 {
  height: 150px;
  position: absolute;
  top: 72%;
  left: 46%;
  z-index: 0;
}

.creativity-trail {
  @media only screen and (max-width: 1625px) {
    &.trail1 {
      top: 65.5%;
    }

    &.trail2 {
      top: 66%;
    }

    &.trail3 {
      top: 7%;
    }
  }

  @media only screen and (max-width: 1600px) {
    &.trail1 {
      top: 68%;
    }

    &.trail2 {
      top: 69%;
    }

    &.trail3 {
      top: 73%;
    }
  }

  @media only screen and (max-width: 1500px) {
    &.trail1 {
      top: 69%;
    }

    &.trail2 {
      top: 70%;
    }

    &.trail3 {
      top: 74%;
    }

    @media only screen and (max-width: 1466px) {
      &.trail2 {
        top: 69%;
      }

      &.trail3 {
        top: 73%;
        left: 44%;
      }
    }

    @media only screen and (max-width: 1371px) {
      &.trail1 {
        top: 67%;
      }

      &.trail2 {
        top: 67%;
      }

      &.trail3 {
        top: 71%;
      }
    }

    @media only screen and (max-width: 1309px) {
      &.trail1 {
        top: 66%;
      }

      &.trail2 {
        top: 66.3%;
      }

      &.trail3 {
        top: 70.5%;
      }
    }

    @media only screen and (max-width: 1200px) {
      &.trail1,
      &.trail2,
      &.trail3 {
        display: none !important;
      }
    }
  }
}

/* Connection popup */

.abraco-stats-top-row,
.abraco-stats-mid-row,
.abraco-stats-bottom-row {
  margin-left: 0;
  margin-right: 0;
}

.abraco-header-row {
  &.row {
    margin-left: 0;
    margin-right: 0;
    margin-top: 40px;
    border-bottom: none;
  }

  .abraco-subheader {
    font-size: 20px;
    font-weight: bold;
  }

  .abraco-header {
    font-weight: bold;
    font-size: 30px;
  }
}

.abraco-stats-top-row {
  height: 266px;
  padding-bottom: 60px;

  .abraco-top-left-wrapper {
    display: flex;
    justify-content: center;
    border-right: 2px dotted $tealColor;

    .abraco-right-arrow-wrapper {
      width: 40px;
      position: relative;

      img {
        position: absolute;
        top: 65px;
        left: 5px;

        @media only screen and (max-width: 1525px) {
          top: 48px;
        }
        @media only screen and (max-width: 1420px) {
          top: 55px;
        }
      }
    }

    .abraco-top-left-stat-wrapper {
      padding: 0;

      &.stat1 {
        width: 220px;
      }

      &.stat2 {
        width: 281px;
        margin-left: 10px;
      }

      .abraco-top-left-stat {
        &.stat1 {
          flex: 1;
        }

        &.stat2 {
          flex: 2;
        }

        padding-top: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .abraco-top-left-text {
          font-weight: bold;
          width: 80%;
          font-size: 18px;
          padding-top: 15px;
        }

        .stat-number-wrapper {
          padding: 0px 10px;
          font-size: 80px;

          .stat-number-bg {
            top: 40%;
            width: 100%;
          }

          @media only screen and (max-width: 1525px) {
            font-size: 60px;
          }

          @media only screen and (max-width: 1420px) {
            font-size: 65px;

            &.stat-number-bg {
              top: 30%;
            }
          }
        }
      }

      @media only screen and (max-width: 1420px) {
        &.stat1 {
          width: 170px;
          padding-right: 15px;
        }

        &.stat2 {
          width: 216px;
          padding-left: 25px;
        }
      }
    }
  }

  .abraco-top-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .abraco-top-right-stat {
      .stat-number-wrapper {
        font-size: 115px;

        @media only screen and (max-width: 1380px) {
          font-size: 90px;
        }
      }
    }

    .abraco-top-right-text {
      font-size: 24px;
      text-align: center;
      width: 80%;
      padding-top: 15px;
      line-height: 1.2;
    }

    @media only screen and (max-width: 1525px) {
      font-size: 95px;

      .abraco-top-right-text {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    height: auto;
    margin-top: 20px;

    .abraco-top-left-wrapper {
      border: none;
    }
  }
}

.abraco-stats-mid-row {
  margin: 0;
  padding: 60px 0px;
  .abraco-mid-left-wrapper {
    display: flex;
    justify-content: center;

    .abraco-mid-left-stat {
      align-items: flex-end;

      .stat-number-wrapper {
        font-size: 120px;
        padding: 0 10px;
        align-items: flex-end;

        .abraco-percent-wrapper {
          display: flex;
          height: 100%;
          align-items: flex-start;
          z-index: 1;

          span {
            font-size: 60px;
            padding-bottom: 60px;
          }
        }

        .abraco-down-arrow-wrapper {
          width: 40px;
          height: 100%;
          z-index: 10;
          position: relative;

          svg {
            position: absolute;
            bottom: 30px;
          }
        }
      }

      .abraco-stat-percent {
        font-size: 50px;
        padding-bottom: 54px;
      }

      .abraco-down-arrow {
        z-index: 10;
        transform: rotate(90deg);
        width: 40px;
        height: 40px;
        margin-bottom: 20px;
      }
    }
  }

  .abraco-mid-right-wrapper {
    display: flex;
    align-items: center;

    .abraco-mid-right-text {
      font-size: 20px;
      font-weight: bold;
    }

    @media only screen and (max-width: 1200px) {
      margin-top: 30px;
      text-align: center;
    }
  }
}

.abraco-stats-bottom-row {
  border-bottom: none;

  .abraco-bottom-stat-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.stat1,
    &.stat2 {
      border-right: 2px dotted $tealColor;
    }

    .abraco-bottom-stat {
      .stat-number-wrapper {
        font-size: 130px;

        @media only screen and (max-width: 1600px) {
          font-size: 120px;
        }

        @media only screen and (max-width: 1200px) {
          font-size: 100px;
        }
      }

      .stat-number-bg {
        margin-left: -10%;
        width: 120%;
      }
    }

    .abraco-bottom-stat-text {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      padding-top: 15px;
      width: 80%;
    }

    @media only screen and (max-width: 992px) {
      &.stat1,
      &.stat2 {
        border: none;
      }

      & + .abraco-bottom-stat-wrapper {
        margin-top: 60px;
      }
    }
  }
}

/* Commitment Popup */

.modal-body-section.commitment-modal {
  background-color: transparent !important;
  padding-bottom: 100px !important;
}

/* Bravery Modal */

.modal-hero-wrapper.video-modal {
  background-image: url('/images/10-year-anniversary/landing-page/10-year-map-bg.jpg');

  .modal-header {
    color: rgb(1, 134, 124);
  }

  .modal-subheader {
    color: black;
    font-weight: bold;
  }

  @media only screen and (max-width: 760px) {
    padding-bottom: 40px;
  }
}

.modal-stats-section.video-modal {
  margin-top: 0;
  background-color: $tealColor;
  padding-bottom: 100px;

  @media only screen and (max-width: 1600px) {
    padding-top: 90px;
  }

  @media only screen and (max-width: 1400px) {
    padding-top: 130px;
  }

  @media only screen and (max-width: 1200px) {
    padding-top: 160px;
  }

  @media only screen and (max-width: 760px) {
    padding-top: 20px;
  }
}

.modal-text.video-modal {
  color: white;
  font-weight: normal;
}

.modal-share-header.video-modal {
  color: white;
}

.share-icon-wrapper.video-modal {
  .share-icon {
    height: 40px;
    width: auto;
  }
}

/* Imagination Modal */

.modal-carousel {
  .carousel-control-prev {
    left: 2.5%;
    width: auto;

    @media only screen and (max-width: 850px) {
      left: 1%;
    }
  }

  .carousel-control-next {
    right: 2.5%;
    width: auto;

    @media only screen and (max-width: 850px) {
      right: 1%;
    }
  }

  .carousel-inner {
    width: 80% !important;
    margin: auto !important;
    height: 100% !important;

    @media only screen and (max-width: 850px) {
      width: 90% !important;
    }

    @media only screen and (max-width: 650px) {
      width: 100% !important;
    }
  }

  .carousel-item {
    height: 100% !important;
    width: 100% !important;
  }
}

.modal-hero-wrapper.imagination-modal {
  padding-bottom: 150px;

  .modal-carousel {
    ol.carousel-indicators {
      bottom: -50px;

      li {
        width: 20px;
        height: 20px;
        margin-left: 7px;
        margin-right: 7px;
        border-radius: 50%;
        opacity: 1;
        border: 3px solid white;
        background: transparent;
        padding: 0 !important;

        &.active {
          background: white;
        }

        @media only screen and (max-width: 1000px) {
          width: 15px;
          height: 15px;
        }

        @media only screen and (max-width: 800px) {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.modal-stats-section.imagination-modal {
  padding-top: 200px;

  @media only screen and (max-width: 1625px) {
    padding-top: 260px;
  }

  @media only screen and (max-width: 1500px) {
    padding-top: 320px;
  }
}

/* GLOBAL VARIABLES */

$headers: 'Invention Light', 'Helvetica', Arial, sans-serif;
$bodyCopy: 'Invention Light', 'Helvetica', Arial, sans-serif;

/* PRIMARY COLORS */
$textColor: #000000;
$linkColor: #00857c;
$whiteColor: #FFFFFF;
$offWhiteColor: #f7f7f7;
$tealColor: #00857c;
$lightTealColor: #6eceb2;
$darkBlueColor: #0c2340;

/* SECONDARY COLORS */
$limeColor: #bfed33;
$lemonColor: #fff063;
$pastelBlueColor: #69b8f7;
$vistaBlueColor: #688ce8;
$richBlueColor: #5450e4;

/* Added Colors */
$redColor: #be0a0a; // Error messages

/* UI COLORS */
$tealHoverColor: #005c55;
$grayColor: #757575;
$lightGrayColor: #cfd2d7;
$whiteGrayColor: #e7e8ea;

/* SIZES */
$full-width: 100%;
$half-width: 50%;
$quarter-width: 25%;

/* CARD OPTIONS */
$card-spacer-x: 5px;
$card-spacer-y: 5px;
$card-img-overlay-padding: 5px;
$card-deck-margin: 5px;
$card-group-margin: 5px;
$card-columns-margin: 5px;
$card-columns-count: 1;
$card-columns-gap: 5px;


/* MEDIA BREAKS */
$break-xlarge: 1600px;
$break-large: 1200px;
$break-medium: 768px;
$break-small: 640px;
$break-xsmall: 360px;